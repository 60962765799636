import variables from '@/helpers/variables';
import moment from 'moment';
import confetti from 'canvas-confetti';
import is from 'is_js';
import i18n from '@/locale/i18n';
import {jsPDF} from 'jspdf';
import {ManropeRegular} from '@/assets/fonts/Manrope/ManropeRegular/ManropeRegular';
import {ManropeBold} from '@/assets/fonts/Manrope/ManropeBold/ManropeBold';

export const debounce = (fn, delay) => {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export const hashStr = (s) => {
  let hash = 0,
    i = 0,
    len = s.length;
  while (i < len) {
    hash = ((hash << 5) - hash + s.charCodeAt(i++)) << 0;
  }
  return hash;
};

export const cropString = (str, maxLength = 50) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

export const isObject = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const isEmptyObj = (obj) => {
  if (!obj || typeof obj !== 'object') return true;

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const hasObjProp = (obj, prop) => {
  if (!obj || typeof obj !== 'object') return false;
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const filterObject = (obj, callback) => {
  if (!obj || typeof obj !== 'object') return obj;
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => callback(value, key)));
};

export const declOfNum = (number, words) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};

export const doubleDigits = (number) => {
  return `${number < 10 ? '0' : ''}${number}`;
};

export const getOrdinal = (number, numerals) => {
  let ord = numerals[3];
  if (number % 10 === 1 && number % 100 !== 11) ord = numerals[0];
  else if (number % 10 === 2 && number % 100 !== 12) ord = numerals[1];
  else if (number % 10 === 3 && number % 10 !== 13) ord = numerals[2];

  return ord;
};

export const range = (start, end) => {
  return [...Array(end).keys()].map((el) => el + start);
};

export const stepsRange = (start, end, step = 1) => {
  return Array.from({length: (end - start) / step + 1}, (value, index) => start + index * step);
};

export const isArraysEqual = (a, b) => {
  if (!a || !b) return false;
  return a.every((itemA) => b.includes(itemA)) && b.every((itemB) => a.includes(itemB));
};

export const uniqueArray = (array) => {
  if (!array || !Array.isArray(array) || !array.length) return array;
  return array
    .map((cur) => JSON.stringify(cur))
    .filter((curr, index, self) => self.indexOf(curr) === index)
    .map((cur) => JSON.parse(cur));
};

export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, {type: mimeType}));
};

export const createHiddenFrame = (src, callback) => {
  const frame = document.createElement('iframe');

  frame.setAttribute('src', src);
  frame.style.position = 'absolute';
  frame.style.width = '0';
  frame.style.height = '0';
  frame.style.visibility = 'hidden';

  document.body.appendChild(frame);

  window.addEventListener
    ? window.addEventListener('message', callback)
    : window.attachEvent('onmessage', callback);
};

export const getFormattedDate = (date, format = 'DD.MM.YYYY', onlyDate = false) => {
  if (!date && typeof date !== 'undefined') return null;
  if (onlyDate) date = date.split('T')[0];
  return moment(date).locale(i18n.locale).format(format);
};

export const getDiffDates = (date, unitOfTime = 'years') => {
  return Math.abs(moment().diff(date, unitOfTime));
};

export const shootConfetti = (config) => {
  const defaultConfig = {
    particleCount: 100,
    spread: 70,
    origin: {y: 0.6},
  };
  confetti({...defaultConfig, ...config});
};

export const clearAlert = () => {
  const {alert} = variables;
  alert.messages = [];
};

export const closeAlert = () => {
  const {alert} = variables;
  alert.visible = false;
  alert.messages = [];
};

export const successAlert = (message) => {
  const {alert} = variables;
  alert.visible = true;
  alert.messages.push(message);
};

export const errorAlert = (message) => {
  const {alert} = variables;
  alert.visible = true;
  alert.messages.push(message);
};

export const getFullName = (metadata, inverse = false) => {
  if (!metadata) return null;

  const middleName = metadata.middle_name || '';
  const firstName = metadata.first_name || '';
  const lastName = metadata.last_name || '';

  return middleName
    ? `${lastName} ${firstName} ${middleName}`
    : inverse
    ? `${firstName} ${lastName}`
    : `${lastName} ${firstName}`;
};

export const refactorUserName = (name) => {
  const [firstName, ...lastNameParts] = name.split(' ');
  const lastName = lastNameParts.join(' ');

  return `${lastName} ${firstName}`;
};

export const getShortName = (metadata) => {
  if (!metadata) return null;

  const middleName = metadata.middle_name || '';
  const firstName = metadata.first_name || '';
  const lastName = metadata.last_name || '';

  return `${lastName} ${firstName.length ? `${firstName[0]}.` : ''} ${
    middleName.length ? `${middleName[0]}.` : ''
  }`;
};

export const getArrayByKey = (array, directory, key, resultKey) => {
  let result = [];

  if (!array || !directory) return result;

  array.forEach((item) => {
    const foundEl = directory.find((el) => el[key] === item);
    if (foundEl) result.push(foundEl[resultKey]);
  });

  return result;
};

export const groupArrayByKey = (array, key) => {
  if (!array || !Array.isArray(array) || !array.length) return array;

  return array.reduce((r, a) => {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, {});
};

export const sortBy = (array, sortKey, inverse = false) => {
  if (!array || !Array.isArray(array) || !array.length) return array;

  function isDate(date) {
    return moment(date, moment.ISO_8601, true).isValid();
  }
  function isShortDate(date) {
    return moment(date, 'DD.MM.YYYY', true).isValid();
  }
  function toFullDate(date) {
    return moment(date, 'DD.MM.YYYY').toString();
  }

  return array.slice().sort((a, b) => {
    if (is.number(a[sortKey]) && is.number(b[sortKey]))
      return inverse ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
    else {
      // if (is.falsy(a[sortKey])) return 1;
      // if (is.falsy(b[sortKey])) return -1;

      if (isDate(a[sortKey]) && isDate(b[sortKey])) {
        return inverse
          ? new Date(a[sortKey]) - new Date(b[sortKey])
          : new Date(b[sortKey]) - new Date(a[sortKey]);
      } else if (isShortDate(a[sortKey]) && isShortDate(a[sortKey])) {
        return inverse
          ? new Date(toFullDate(a[sortKey])) - new Date(toFullDate(b[sortKey]))
          : new Date(toFullDate(b[sortKey])) - new Date(toFullDate(a[sortKey]));
      } else {
        return inverse
          ? String(b[sortKey]).toLowerCase().localeCompare(String(a[sortKey]).toLowerCase())
          : String(a[sortKey]).toLowerCase().localeCompare(String(b[sortKey]).toLowerCase());
      }
    }
  });
};

export const copyToClipboard = (content, successCallback, errorCallback) => {
  const unsecuredCopyToClipboard = (text) => {
    const copyTextarea = document.createElement('textarea');

    copyTextarea.style.position = 'absolute';
    copyTextarea.style.opacity = '0';

    copyTextarea.textContent = text;

    document.body.appendChild(copyTextarea);

    const selection = document.getSelection();
    const range = document.createRange();

    range.selectNode(copyTextarea);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      successCallback();
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
      errorCallback();
    }

    selection.removeAllRanges();
    document.body.removeChild(copyTextarea);
  };

  window.isSecureContext && navigator.clipboard
    ? navigator.clipboard
        .writeText(content)
        .then(() => successCallback())
        .catch(() => errorCallback())
    : unsecuredCopyToClipboard(content);
};

export const getFile = (url) => {
  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getPDFFile = (options, fileName, texts, images) => {
  const pdf = new jsPDF(...options);

  if (texts && texts.length) {
    pdf.addFileToVFS('ManropeRegular.ttf', ManropeRegular);
    pdf.addFont('ManropeRegular.ttf', 'ManropeRegular', 'normal');
    pdf.addFileToVFS('ManropeBold.ttf', ManropeBold);
    pdf.addFont('ManropeBold.ttf', 'ManropeBold', 'normal');

    texts.forEach((text) => {
      pdf.setFont(text.fontName);
      pdf.setFontSize(text.fontSize);
      pdf.text(text.content, text.position[0], text.position[1]);
    });
  }

  if (images && images.length) {
    images.forEach((img) => {
      pdf.addImage(
        img.data,
        img.format,
        img.position[0],
        img.position[1],
        img.size[0],
        img.size[1]
      );
    });
  }

  pdf.save(`${fileName || 'Report'}.pdf`);
};

export const isFileExists = (url) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status === 200;
};

export const blobToFile = (data, type = '', extension, fileName) => {
  const href = window.URL.createObjectURL(new Blob([data], {type}));
  const link = document.createElement('a');

  link.href = href;
  link.download = `${fileName || 'Report'}.${extension || 'txt'}`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const scrollTop = (wrapSelector) => {
  let mainWrap = document.querySelector(wrapSelector ? wrapSelector : 'html');
  if (mainWrap) mainWrap.scrollTop = 0;
};

/**
 * Функция для прокрутки страницы к элементу
 * @param selector – CSS-селектор элемента, к которому нужно прокрутить страницу
 * @param options – опции для scrollIntoView API
 * @param offset – учитывать ли апп-бар
 */
export function scrollTo(selector, options = {behavior: 'smooth'}, offset) {
  const el = document.querySelector(selector ? selector : 'html');

  if (el) {
    if (offset) {
      const y = el.getBoundingClientRect().top + window.scrollY - (offset || 0);
      window.scrollTo({top: y, behavior: 'smooth'});
    } else el.scrollIntoView(options);
  }
}

export function clearText(text = '') {
  return text.replace(/(<([^>]+)>)/gi, '');
}

export const setCookie = (name, value, expires) => {
  let cookieString = `${name}=${value};`;
  if (expires) cookieString = `${cookieString} expires=${expires};`;
  document.cookie = cookieString;
};

export const clearCookie = (name) => {
  document.cookie = `${name}=""; expires=-1;`;
};

export const camelize = (s) => {
  return (s || '').replace(/_./g, (x) => x[1].toUpperCase());
};

export const kebabToCamel = (s) => {
  return (s || '').replace(/-./g, (x) => x[1].toUpperCase());
};

export const camelToKebab = (s) => {
  return (s || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const camelToSnake = (s) => {
  return (s || '').replace(/[A-Z]/g, (c) => '_' + c.toLowerCase());
};

export const convertToRGB = (hexColor) => {
  if (hexColor.length !== 6) throw 'Only six-digit hex colors are allowed.';
  let aRgbHex = hexColor.match(/.{1,2}/g);
  return [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
};

export const getRGBAColor = (hexColor = '', alpha = 1) => {
  const RGB = convertToRGB(hexColor.replace('#', '')) || [];
  return RGB.length ? `rgba(${RGB[0]}, ${RGB[1]}, ${RGB[2]}, ${alpha})` : '';
};

/**
 * Исправление путей к картинкам от бэка (обрезаем лишние [..])
 * @param {*} url
 */
export const fixImageUrl = (url) => {
  if (url) {
    if (url.startsWith('http') || url.startsWith('/')) return url;
    if (url.startsWith('../') || url.startsWith('/')) return url.slice(2);
    return `/${url}`;
  }
  return url;
};

export const isFunction = (item) => {
  return typeof item === 'function';
};

export const isNumber = (value) => {
  return typeof value === 'number';
};

export const getNormalizedSlot = (name, {$slots, $scopedSlots}) => {
  const slot = $scopedSlots[name] || $slots[name];
  return isFunction(slot) ? slot() : slot;
};

export const getHeightElToBottomScreen = (selector) => {
  const el = document.querySelector(selector);
  if (!el) return 'auto';

  return window.innerHeight - Math.floor(el.getBoundingClientRect().top) + 'px';
};

export const getHeightEl = (selector) => {
  const el = document.querySelector(selector);
  return el ? `${el.offsetHeight}px` : 'auto';
};
