export default {
  userMetadata: {
    firstName: 'First name',
    lastName: 'Last name',
    middleName: 'Middle name',
    fullName: 'Full name',
    city: 'City',
    address: 'Address',
    TIN: 'TIN',
    phone: 'Phone',
    password: 'Password',
    email: 'E-mail',
    messengers: 'Messengers',
    website: 'Website',
    specialization: 'Specialization',
    teams: 'Teams',
    level: 'Level',
    salary: 'Salary',
    startOfWorkCompany: 'Start of work in the company',
    position: 'Position',
    startOfWorkPosition: 'Start of work in the position',
    birthdate: 'Birthdate',
    familyStatus: 'Marital status',
    children: 'Children',
    education: 'Education',
    specialty: 'Specialty',
  },
  familyStatuses: {
    widower: 'Widower (widow)',
    neverMarried: 'Never been married',
    divorced: 'Officially divorced (divorce is registered)',
    brokeUp: 'Broke up',
    married: 'Is in a registered marriage',
    unregisteredMarriage: 'Is in an unregistered marriage',
  },
  homePage: 'Home',
  myProfile: 'My profile',
  myProgress: 'My progress',
  myTasks: 'My tasks',
  assignedToMe: 'Assigned to me',
  myMeetings: 'My meetings',
  myLevel: 'My current level',
  myCompany: 'My company',
  myCompanies: 'My companies',
  myResume: 'My CV',
  curatedByMe: 'Curated by me',
  administration: 'Profile designer',
  adminArea: 'Administration',
  users: 'Users',
  usersManagement: 'Users management',
  administrator: 'Administrator',
  staffManagement: 'Staff management',
  teamManagement: 'Team management',
  progress: 'Progress',
  profile: 'Profile',
  taskBoard: 'Task board',
  taskBoards: 'Progress plans',
  company: 'Company',
  companies: 'Companies',
  companyProfile: 'Company profile',
  feedbackFeed: 'Feedback feed',
  companyFeed: {
    title: 'Feed',
    settings: 'Feed settings',
    settingsHint: 'Check the questions you want to hide from the feed',
    placeholder:
      'There is nothing in the feed yet. For comments to appear, wait for the organizer to assign a survey.',
  },
  orgStructure: 'Organizational structure',
  licensing: 'Licensing',
  modules: 'Modules',
  modulesMetadata: {
    adaptation: {
      title: 'Adaptation',
      description:
        'Automation of onboarding, adaptation and commissioning via a chat bot in Telegram.<br/>The designer of adaptation tracks and ready-made templates.<br/>Tracking progress along the entire path of employee adaptation.',
    },
    evaluation: {
      title: 'Score and development',
      description:
        'Conducting regular appraisals or spot evaluations of employees.<br/>Profile builder with ready-made templates.<br/>Assessment 360/180 with flexible settings and automatic IPR based on results.<br/>Employees can take surveys in the platform interface, as well as via the Beehive-bot in Telegram.<br/>Individual and summary reports.',
    },
    targets: {
      title: 'Targets',
      description:
        'Functionality for setting and tracking employee targets.<br/>Helps evaluate team performance in real time.<br/>The module allows you to set tasks and targets for different periods of time and prioritize them, collect results and see a report for the entire team, for a department or for a specific person. With its help, you can set development targets or probationary periods and store the entire performance history of your employees.',
    },
    meetings: {
      title: 'Meetings',
      description:
        'Planning and conducting 1-on-1 meetings.<br/>Tracking meetings using a calendar.<br/>Recording of results and tasks based on the results of meetings.',
    },
    testsAndSurveys: {
      title: 'Tests and surveys',
      description:
        'Designer of surveys and tests for conducting HR research and evaluating employee knowledge.<br/>Ready-made research templates with the best HR practices.<br/>A ready-made solution for assessing employee satisfaction, loyalty (eNPS), engagement and burnout.<br/>Formation of a culture of engagement through a regular and simple format of the event.',
    },
    feedback: {
      title: 'Feedback',
      description:
        'Continuous feedback using a method that combines a 360-degree assessment and pulse surveys of employees.<br/>Smart surveys in which employees give feedback to their colleagues.<br/>Intelligent selection of interacting employees based on real communications.<br/>Access to the «Employee Effectiveness» methodology, which contains questions on the competencies, efficiency and well-being of employees.',
    },
    interconnect: {
      title: 'Communications analytics',
      description:
        'Analysis of communications and real workflow in the company based on mail, calendar and task management systems.',
    },
    htTests: {
      title: 'Personal tests',
      description:
        'Psychological tests from our partners of the Laboratory of Humanitarian Technologies.<br/>Assessment of the motivation of the employee or the entire team, the structure of the personality and potential of the employee, the assembly of the team according to the roles of Belbin.',
    },
  },
  contacts: 'Contacts',
  documents: 'Documents',
  instructions: 'Instructions',
  legalInfo: 'Legal information',
  backgroundInfo: 'Background information',
  addInfo: 'Additional information',
  aboutCompany: 'About company',
  jobInfo: 'Job Information',
  changelog: 'Software updates',
  aboutMe: 'About me',
  employees: 'Employees',
  employeeStatuses: {
    current: 'Current',
    dismissed: 'Dismissed',
    invited: 'Invited',
  },
  team: 'Team',
  teams: 'Teams',
  withoutTeam: 'Without a team',
  leader: 'Leader',
  surveys: 'Surveys',
  survey: 'Survey',
  result: 'Result',
  surveyResults: 'Survey results',
  periodicSurvey: 'Periodic survey',
  recipients: 'Recipients',
  periodLimit: 'The period of passing the test',
  periodExpired: 'The set period has expired',
  surveysTypes: {
    engagement: 'Engagement and satisfaction',
    burnout: 'Burnout',
    corporateCulture: 'Corporate culture',
  },
  surveysMetadata: {
    surveys: {
      title: 'Surveys',
      periodic: 'Periodic survey',
      nonperiodic: 'Non-periodic survey',
      report: 'Summary',
      details: 'By individual users',
      emplStatistic: 'Employees passed the survey',
      allQuestions: 'All',
      createButton: 'Create a new survey',
      surveyDone: 'Survey is done',
      surveyPlaceholder: 'There are no scheduled or pending surveys yet',
      table: {
        headers: {
          name: 'Name',
          lastSend: 'Last send',
          nextSend: 'Next send',
          dateStart: 'Start date',
          dateEnd: 'End date',
          period: 'Period',
          count: 'Statistic',
          status: 'Status',
        },
      },
      statuses: {
        draft: 'Draft',
        published: 'Published',
      },
      changeButton: 'Edit a survey',
      menu: {
        sendToParticipants: 'Assign the participants',
        sendNow: 'Send now',
        makeUnavailable: 'Make unavailable',
        makeAvailable: 'Make available',
        exportResults: 'Export results',
        copyLink: 'Copy the link',
        copyEntity: 'Copy the survey',
        copyEntityTo: 'Copy into other company',
        del: 'Delete survey',
      },
      successMessages: {
        changeStatus: 'Survey availability changed successfully',
        copyEntity: 'Survey successfully copied',
        sendNow: 'Survey successfully sent',
      },
      errorMessages: {
        changeStatus: 'Failed to change survey availability',
        copyEntity: 'Failed to copy survey',
        sendNow: 'Failed to send survey',
        copyEntityNotSelected: 'No one company selected',
      },
    },
    tests: {
      title: 'Tests',
      unregistered: 'Unregistered user',
      validAnswers: 'Answers valid',
      periodic: 'Periodic test',
      nonperiodic: 'Non-periodic test',
      createButton: 'Create a new test',
      testDone: 'Test is done',
      testPlaceholder: 'No tests scheduled yet',
      table: {
        headers: {
          name: 'Name',
          lastSend: 'Last send',
          nextSend: 'Next send',
          dateStart: 'Start date',
          dateEnd: 'End date',
          period: 'Period',
          count: 'Count results',
          availability: 'Allowed for guests',
          status: 'Status',
        },
      },
      statuses: {
        draft: 'Draft',
        published: 'Published',
      },
      changeButton: 'Edit a test',
      menu: {
        sendToParticipants: 'Assign the participants',
        sendNow: 'Send now',
        makeUnavailable: 'Make unavailable',
        makeAvailable: 'Make available',
        exportResults: 'Export results',
        copyLink: 'Copy the link',
        copyEntity: 'Copy the test',
        copyEntityTo: 'Copy into other company',
        del: 'Delete the test',
      },
      successMessages: {
        changeStatus: 'Test availability changed successfully',
        copyEntity: 'Test successfully copied',
        sendNow: 'Test successfully sent',
      },
      errorMessages: {
        changeStatus: 'Failed to change test availability',
        copyEntity: 'Failed to copy test',
        sendNow: 'Failed to send test',
        copyEntityNotSelected: 'No one company selected',
      },
    },
    availability: {
      availabilityTooltip:
        'Unauthorized users are allowed to pass. The results of the passage will be depersonalized.',
      availabilityGuestTooltip:
        'The results of the survey/test will not display information about the user.',
      availabilityNotGuestTooltip:
        'The results of the survey/test display information about all users, if the user is not logged in, then his answers will be marked as guest answers.',
    },
    cycle: 'Cycle',
    questions: {
      phone: 'Phone',
      notFound: 'Question type not recognized',
      boolean: 'Yes or no',
      number: 'Number',
      text: 'Short text',
      date: 'Date',
      textarea: 'Long text',
      file: 'File',
      scale: 'Scale',
      time: 'Time',
      default: '- Choose question type -',
      multi_choice: 'Multi - selection',
      single_choice: 'Single choice',
    },
    questionFields: {
      validAnswer: 'Right answer',
      showValidAnswer: 'Specify the correct answer',
      units: 'Units of measurement',
      minMax: 'Min/max value',
      unitsPlaceholder: 'For example: pcs, kg, days',
      from: 'From',
      to: 'To',
      decimals: 'Allow decimals',
      answerVariants: 'Answer option',
      scaleMaxLength: 'Maximum length of titles: 30 characters',
      scaleMaxLengthHint:
        'The scale will be displayed horizontally only with numbers, if texts are indicated in the scale values - then the scale will be displayed vertically',
      answerVariantsPlaceholder: 'Enter the name of the answer option',
      addVariant: 'Add another option',
      delVariant: 'Delete an option',
      pointCount: 'Number of points',
      startsWith: 'Starts with «1» value',
      scaleBegin: 'Scale begin',
      scaleBeginPh: 'Enter the name of the first point',
      scaleEnd: 'Scale end',
      scaleEndPh: 'Enter the name of the last point',
      maxLength: 'Length limitation',
      addFile: 'Attach a file',
      addedFile: 'Attached file',
      surveyTextQuestionLabel: 'Answer',
      showInFeed: 'Enable the display of a question in the feed',
      showInFeedHint:
        'The answer will be published in the <strong> feed without specifying your name</strong>',
      question: 'Question',
      questionPlaceholder: 'Enter the question text',
      qType: 'Question type',
      showRich: 'Include an extended description of the question',
      richBody: 'Extended description',
      surveySettingsAdditional: 'Evaluation of the result is not included',
      surveySettingsAdditionalBtn: 'Turn on',
      surveySettingsAdditionalLink: 'Go to setting up the evaluation of results',
      newOption: 'New version',
      copyQuestion: 'Copy a question',
      copyPrefix: 'Copy',
      editQuestion: 'Edit the question',
      deleteForever: 'Delete without the possibility of recovery',
      moveDown: 'Move below',
      moveUp: 'Move higher',
      restore: 'Restore',
      finishButtonName: 'Complete the passage',
      addQuestion: 'Add a question',
      deleted: 'The question {num} has been deleted.',
    },
    feedbacks: {
      title: 'Feedback',
      report: 'Summary',
      details: 'By individual users',
      summaryOfQuestions: 'Summary of questions',
      emplStatistic: 'Employees passed the survey',
      allQuestions: 'All',
      surveyDone: 'Survey is done',
      createButton: 'Create a new feedback',
      changeButton: 'Change the feedback',
      managingMethodologies: 'Managing methodologies',
      methodology: 'The methodology',
      commonMethodology: 'The common methodology',
      listOfQuestions: 'List of questions',
      placeholder:
        'Surveys and passing results will be displayed here. To start the assessment, click the «Create survey» button.',
      table: {
        headers: {
          name: 'Name',
          status: 'Status',
          lastStart: 'Cycle start',
          nextStart: 'Start of the next cycle',
          count: 'Statistic',
          cycle: 'Cycle',
        },
      },
      statuses: {
        started: 'Started',
        stopped: 'Stopped',
      },
      menu: {
        copyEntity: 'Copy the feedback',
        deleteEntity: 'Delete the feedback',
      },
      infoMessages: {
        start:
          'At start, the survey parts will be sent on the selected days of the week. If the survey was stopped earlier, then a new sending cycle will begin, i.e. the polls will start coming again. Scheduled submissions of survey parts from the previous cycle will be canceled.',
        stop: 'Sending parts of surveys from this cycle will continue until the survey is completed in full, but a new survey cycle will not be started.',
      },
      successMessages: {
        copyEntity: 'Survey successfully copied',
        deleteEntity: 'Survey successfully deleted',
      },
      errorMessages: {
        copyEntity: 'Failed to copy the survey',
        deleteEntity: 'Failed to delete the survey',
      },
    },
    researchment: {
      title: 'Researchments',
      titleOne: 'Researchment',
      buttons: {
        started: 'Stop',
        stopped: 'Run',
        paused: 'Continue',
      },
      statuses: {
        started: 'Started',
        stopped: 'Stopped',
        paused: 'Paused',
      },
    },
  },
  dashboardMetadata: {
    titles: {
      competencies360: 'Competencies 360',
      knowledge: 'Knowledge',
      personalPotential: 'Personal potential',
      conflictLevel: 'The level of conflict',
      managementStyle: 'Management style (Adizes)',
      teamRoles: 'Team roles (Belbin)',
      targets: 'Targets',
      feedbacks: 'Feedbacks',
    },
    managementStyles: {
      unusual: 'Unusual style',
      lackOfTrends: 'Lack of trends',
      moderate: 'Moderate expression of style',
      vivid: 'Vivid expression of style',
    },
    teamRoles: {
      preferredRoles: 'Preferred roles',
      unwantedRoles: 'Unwanted roles',
    },
  },
  meetings: 'Meetings',
  meeting: 'Meeting',
  meetingsMetadata: {
    roles: {
      participant: 'My meetings',
      supervisor: 'I assigned',
    },
    statuses: {
      planned: 'Planned',
      done: 'Done',
      overdue: 'Overdue',
      cancelled: 'Cancelled',
    },
    formats: {
      online: 'Online',
      offline: 'Offline',
    },
    statusesDescriptions: {
      planned: 'The meeting is scheduled, you can reschedule the meeting for another time and date',
      done: 'The meeting is over, you can specify the results, as well as add tasks to yourself and the employee',
      overdue: 'The meeting has expired, you can reschedule the meeting for another time and date',
      cancelled: 'The meeting was canceled',
    },
    buttons: {
      addAnotherQuestion: 'Add another question',
      goToMeeting: 'Go to the meeting',
      viewTasks: 'View tasks',
      doneMeeting: 'Done the meeting',
      cancelMeeting: 'Cancel the meeting',
    },
    agenda: 'Agenda',
    myTasks: 'My tasks',
    tasksForEmployee: 'Tasks for the employee',
    tasksForSupervisor: 'Tasks for the supervisor',
    meetingRoom: 'Meeting room',
    resultsMeeting: 'Results of the meeting',
    listOfQuestions: 'List of questions',
    createMeeting: 'Schedule a meeting',
    role: 'Role',
  },
  tests: 'Tests',
  test: 'Test',
  pulseTest: 'Pulse-survey',
  scoreType: 'Score type',
  duration: 'Duration',
  comment: 'Comment',
  comments: 'Comments',
  autofill: 'Autofill',
  status: 'Status',
  scoreAndDevelopment: 'Score and development',
  score: 'Score',
  score360: 'Score 360',
  approvalScore360: 'Approval of interacting for assessment 360',
  negativeScore: "can't evaluate",
  averageScore: 'Average score',
  testsTypes: {
    tests360: 'Assessment 360',
    self360: 'Self-assessment 360',
    approval360: 'Approve the list',
    pulseTests: 'Pulse-surveys',
    self: 'Self-assessment',
    notSelf: 'Assessment of other users',
    selfApproval: 'Self-approval',
    notSelfApproval: 'Approval of other users',
    personal: 'Personal tests',
    feedback: 'Feedback',
  },
  unlimitedLicenses: 'The number of licenses is not limited',
  skill: 'Skill',
  skills: 'Skills',
  skillsTypes: {
    soft: 'Soft skills',
    hard: 'Hard skills',
    motivation: 'Career development direction and motivation',
    teamRoles: 'Roles in the team',
  },
  competence: 'Competence',
  competencies: 'Competencies',
  competenceBlock: 'Competence block',
  indicator: 'Indicator',
  completionStatuses: {
    completed: 'Completed',
    notCompleted: 'Not completed',
  },
  approvalStatuses: {
    awaitingCreator: 'Awaiting creator',
    awaitingEvaluated: 'Awaiting evaluated',
    awaitingSupervisor: 'Awaiting supervisor',
    pending: 'Pending',
    approved: 'Approved',
    started: 'Started',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
  },
  approvalEvaluatedStatuses: {
    pending: 'Pending by the evaluated',
    approved: 'Approved by the evaluated',
    rejected: 'Rejected by the evaluated',
  },
  type: 'Type',
  environment: 'Environment',
  supervisors: 'Supervisors',
  subordinates: 'Subordinates',
  colleagues: 'Colleagues',
  experts: 'Experts',
  expertRoles: {
    evaluated: 'Evaluated',
    supervisor: 'Supervisor',
    subordinate: 'Subordinate',
    colleague: 'Colleague',
  },
  reEvaluationLock: 'Configuring session closure',
  report: 'Report',
  reports: 'Reports',
  fixedDevPlan: 'Fixed IDP',
  devPlan: 'Development plan',
  devPlans: 'Development plans',
  materials: 'Materials',
  commonMaterials: 'Common materials',
  adaptationPlans: 'Adaptation plans',
  roadmapsTypes: {
    roadmap: 'IDP by self-assessment',
    roadmap360: 'IDP by 360',
  },
  importance: 'Importance',
  importanceTypes: {
    extra: 'Extra',
    high: 'High',
    middle: 'Middle',
    low: 'Low',
    noMatter: 'No matter',
  },
  conflictLevels: {
    high: 'High',
    mid: 'Middle',
    low: 'Low',
  },
  target: 'Target',
  targetLevel: 'Target level',
  targets: 'Targets',
  targetsMetadata: {
    roles: {
      self: 'My',
      curator: 'Curated by me',
    },
    statuses: {
      awaitingReview: 'Awaiting review',
      approved: 'Approved',
      inProgress: 'In progress',
      inReview: 'In review by supervisor/curator',
      inReviewAssignee: 'In review by assignee',
      completed: 'Completed',
      cancelled: 'Cancelled',
    },
    buttons: {
      approve: 'Approve',
    },
    curator: 'Supervisor/Curator',
    noCurator: 'not assigned',
  },
  period: 'Period',
  schedule: 'Schedule',
  date: 'Date',
  deadline: 'Deadline',
  version: 'Version',
  currentVersion: 'Current version',
  mentor: 'Mentor',
  adaptation: 'Adaptation',
  adaptations: 'Adaptations',
  interactionDiagram: 'Interaction diagram',
  testing: 'Testing',
  plan: 'Plan',
  creationDate: 'Creation date',
  task: 'Task',
  tasks: 'Tasks',
  currentTasks: 'Current tasks',
  portfolio: 'Portfolio',
  services: 'Services',
  workExp: 'Work experience',
  organizers: 'Organizers',
  organizer: 'Organizer',
  managers: 'Managers',
  manager: 'Manager',
  education: 'Education',
  teamMembers: 'Team members',
  feedbacks: 'Feedbacks',
  massAssignment: 'Mass assignment',
  name: 'Name',
  educationLevels: {
    top: 'Higher education',
    bachelor: "Bachelor's degree",
    magister: "Master's degree",
    topHighest: 'Higher education - training of highly qualified personnel',
    candidate: 'Candidate of Sciences',
    adjunct: 'Adjunct',
    aspirant: 'Post-graduate student',
    doctor: 'Doctor of Sciences',
    auxKidsAdults: 'Additional education for children and adults',
    auxProf: 'Additional professional education',
    infants: 'Preschool education',
    intern: 'Internship',
    starterCommon: 'Primary education',
    starterProf: 'Primary professional education',
    unfinishedTop: 'Unfinished higher education',
    ordinate: 'Residency',
    mainCommon: 'Basic education',
    afterUni: 'Postgraduate education',
    profTraining: 'Vocational training',
    middleFull: 'Graduated from Secondary school',
    middle: 'Completed high school',
    middleSpecial: 'Community College',
  },
  specialty: 'Specialty',
  specializations: 'Specializations',
  statistics: 'Statistics',
  analytics: 'Analytics',
  interconnect: 'Interaction diagram',
  settings: 'Settings',
  demoAccess: 'Demo access',
  buttons: {
    share: 'Share',
    export: 'Export',
    exportToExcel: 'Export to Excel',
    exportReport: 'Export a report',
    copy: 'Copy',
    download: 'Download',
    downloadDocument: 'Download document',
    downloadAllFiles: 'Download all files',
    downloadTemplate: 'Download template',
    uploadSpecialization: 'Upload specialization',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    loadMore: 'Load more',
    toTop: 'To top',
    link: 'Click',
    save: 'Save',
    saveChanges: 'Save changes',
    create: 'Create',
    fixate: 'Fixate the version',
    generate: 'Generate',
    restart: 'Restart',
    refresh: 'Refresh',
    find: 'Find',
    remind: 'Remind',
    edit: 'Edit',
    configure: 'Configure',
    change: 'Change',
    add: 'Add',
    addAnother: 'Add another',
    addTask: 'Add a task',
    addLink: 'Add a link',
    addQuestion: 'Add a question',
    addQuestions: 'Add a questions',
    addToBoard: 'Add to the board',
    addTarget: 'Add a target',
    addComment: 'Add a comment',
    editQuestions: 'Edit the questions',
    editTask: 'Edit the task',
    appoint: 'Appoint',
    disabled: 'Disabled',
    enabled: 'Enabled',
    createTests: 'Create tests',
    createScore: 'Create score',
    sendScore: 'Send score',
    sendForApproval: 'Send for approval',
    send: 'Send',
    evaluate: 'Evaluate',
    evaluateAgain: 'Evaluate again',
    takeTest: 'Take the test',
    takeSurvey: 'Take the survey',
    takeTestAgain: 'Take the test again',
    continueTest: 'Continue the test',
    devPlan: 'and get a development plan',
    compositionOfParticipants: 'Composition of participants',
    approveList: 'Approve the list',
    createReport: 'Create report',
    createDevPlan: 'Create IDP',
    checkReport: 'Check report',
    checkDevPlan: 'Check development plan',
    createDiagram: 'Create a diagram',
    completeTest: 'Complete the test',
    complete: 'Complete',
    invite: 'Send invitations',
    accept: 'Accept',
    reject: 'Reject',
    approve: 'Approve',
    open: 'Open',
    openAll: 'Open all',
    select: 'Select',
    selectAllPeople: 'Select all',
    selectAll: 'Select all',
    deselect: 'Cancel select',
    reset: 'Reset',
    cancel: 'Cancel',
    cancelAction: 'Cancel',
    cancelTest: 'Cancel the test',
    recover: 'Recover',
    delete: 'Delete',
    deleteAll: 'Delete all',
    confirm: 'Confirm',
    confirmDelete: 'Yes, delete',
    close: 'Close',
    closeAll: 'Close all',
    login: 'Login',
    exit: 'Exit',
    clear: 'Clear',
    done: 'Done',
    continue: 'Continue',
    back: 'Back',
    goBack: 'Go back',
    goToDashboard: 'Go to dashboard',
    goToBoard: 'Go to board',
    moveUp: 'Move up',
    moveDown: 'Move down',
    applyFilter: 'Apply filter',
    editFilter: 'Edit filter',
    clearFilter: 'Clear filter',
    block: 'Block',
    unblock: 'Unblock',
    action: 'Actions',
    readAll: 'Read all',
    inDynamics: 'In dynamics',
    showReports: 'Show the reports',
    hideReports: 'Hide the reports',
    getToWork: 'Get to work',
    logging: 'Logging',
  },
  toggleGroups: {
    participateTypesHint: 'Here you can choose who will be available to take the survey/test',
    participateTypes: {
      guest: 'It is available to all users, authorized and unauthorized',
      authorized: 'It is available only to authorized users',
      assigned: 'It is available only to authorized users for whom this survey/test was assigned',
      names: {
        guest: 'Allowed to guests',
        authorized: 'Only authorized',
        assigned: 'Assigned only',
      },
    },
    participateAnonymousHint:
      'Here you can restrict the collection of user data. Does not affect the availability of passage',
    participateAnonymousHintDisabled:
      'Here you can restrict the collection of user data. Does not affect the availability of passage. Will not apply to running surveys or if they already have answers',
    participateAnonymous: {
      anonymousName: 'Anonymous survey',
      anonymous: 'The results of the survey/test will not display information about the user',
      notAnonymousName: 'Not an anonymous survey',
      notAnonymous:
        'The results of the survey/test display information about all users, if the user is not logged in, then his answers will be marked as guest answers',
    },
  },
  inputs: {
    file: 'Select a file',
    image: 'Select a image',
  },
  loading: 'Loading...',
  search: 'Search',
  searchByFullName: 'Search by full name',
  searchByTitle: 'Search by name',
  searchByTeam: 'Search by team',
  searchBySpec: 'Search by specialization',
  searchByQuestion: 'Search by question',
  link: 'Link',
  file: 'File',
  tags: 'Tags',
  description: 'Description',
  info: {
    copyText: 'Text copied to clipboard',
    copyLink: 'Link copied to clipboard',
  },
  socialNetworks: {
    behance: 'Behance',
    facebook: 'Facebook',
    github: 'GitHub',
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    ok: 'OK',
    stackoverflow: 'Stack Overflow',
    telegram: 'Telegram',
    twitter: 'Twitter',
    vk: 'VK',
    skype: 'Skype',
  },
  stubs: {
    noItems: 'Not a single item has been added yet',
    noInfo: 'No information',
    noData: 'No data',
    noTitle: 'No title',
    nothingFound: 'Nothing was found',
    notSpecified: 'Not specified',
    noDescription: 'No description',
    cityNotSpecified: 'City not specified',
    companyNotSelected: 'Company not selected',
    notCompleted: 'Not completed',
    notSelected: 'Not selected',
    notAssigned: 'Not assigned',
    noSpecs: 'No specializations',
    noVersions: 'No versions',
    noComment: 'No comment',
    noComments: 'No comments',
    noQuestions: 'No questions',
    noTheme: 'No theme',
    withoutTeam: 'Without a team',
    teamSpecsNotSelected:
      'Team specializations are not selected. You can select them by clicking on the «Edit» button next to the user name or on the «Team members» tab',
    noSkills: 'There are no skills to evaluate',
    noCompanies: 'No companies',
    noEmployees: 'No employees',
    noSubEmployees: 'No subordinate employees',
    noTests: 'Testing was not assigned',
    noTeams: 'To start the assessment, create at least one team and add employees to it',
    noScore: 'Not a single assessment was assigned',
    noResults: 'No results',
    noMethodology:
      'You do not have any methodology yet. To create a methodology, click on the «Create a methodology» button',
    noTemplate:
      'You do not have any templates yet. To create a template, create a researchment and save it as a template',
  },
  placeholders: {
    specifyNumber: 'Specify the number',
    select: 'Select from the list',
    statisticInterval: 'Data for the last 60 days',
    teamsSelect: 'All over the company',
    selectCompany: 'Select a company',
    all: 'All',
  },
  tooltips: {
    employees:
      'Shows the number of employees on the current date. Does not depend on the selected period',
    adaptation:
      'Number of employees with at least one onboarding plan not completed. Does not depend on the selected period',
    score360:
      'Statistics on running assessment 360 for the selected period. Development cards are taken into account only fixed',
    pulseTests:
      'Employee activity based on surveys sent, which are periodic mailings. The statistics do not include surveys sent via the link',
    personalTests:
      'Statistics on psychological tests. Does not depend on the selected period and the selected teams. To replenish licenses, contact technical support',
    testing:
      'Statistics on running tests. The statistics do not include tests submitted via the link',
    remindTests:
      'To send reminders for the test, set the checkbox and click on the «Remind» button',
    support: 'Write to tech support',
    targets:
      'Statistics on targets set displays the total number of targets and the average efficiency score for all employees of the company. The output of statistics on the target block does not depend on the selected period',
  },
  attention: 'Attention',
  uploaded: 'Uploaded',
  string: 'String',
  error: 'Error',
  errorMessages: {
    default: 'Failed to load data',
    permissionDenied: 'Not enough rights',
    exportReport: 'Failed to export the report',
    personalTests: '«Motivation and team roles» tests are temporarily unavailable',
    specializations:
      'Create specializations for the company {company_id}, otherwise the team page will be unavailable',
  },
  yes: 'Yes',
  no: 'No',
  of: 'of',
  by: 'by',
  year: 'year',
  month: 'month',
  day: 'day',
  from: 'from',
  to: 'to',
  atTime: "at {time} o'clock",
  shorthands: {
    quarter: 'q',
    date: {
      year: 'y',
      months: 'm',
    },
    currency: {
      rub: 'RUB',
    },
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  },
  workDays: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  quantity: {
    targets: ['target', 'targets', 'targets'],
    scores: ['point', 'points', 'points'],
    requiredHint: ['mandatory', 'mandatory', 'mandatory'],
    email: ['email', 'emails', 'emails'],
    evaluation: ['evaluation', 'evaluations', 'evaluations'],
    numerals: ['st', 'nd', 'rd', 'th'],
    years: ['year', 'years', 'years'],
    months: ['month', 'months', 'months'],
    days: ['day', 'days', 'days'],
    team: ['team', 'team', 'teams'],
    task: ['task', 'tasks', 'tasks'],
    people: ['person', 'people', 'people'],
    survey: ['survey', 'surveys', 'surveys'],
    answer: ['answer', 'answers', 'answers'],
    license: ['license', 'licenses', 'licenses'],
    specialization: ['specialization', 'specializations', 'specializations'],
    employee: ['employee', 'employees', 'employees'],
    user: ['user', 'users', 'users'],
    test: ['test', 'tests', 'tests'],
    material: ['material', 'materials', 'materials'],
    question: ['question', 'questions', 'questions'],
    element: ['element', 'elements', 'elements'],
    minute: ['minute', 'minutes', 'minutes'],
    selected: {
      masculine: ['Selected', 'Selected', 'Selected'],
      feminine: ['Selected', 'Selected', 'Selected'],
    },
    available: {
      feminine: ['Available', 'Available', 'Available'],
    },
  },
  validators: {
    required: 'Required field',
    invalidValue: 'Invalid value',
    positiveInt: 'Enter a positive integer',
    positiveIntWithZero: 'Enter a positive number or zero',
    phone: 'Incorrect phone number',
    email: 'Incorrect e-mail',
    minLength: 'Minimum of {minLimit} characters',
    minValue: 'Minimum value: {minLimit}',
    maxValue: 'Minimum value: {maxLimit}',
    maxLength: 'The length should not exceed',
    url: 'Incorrect URL',
    file: 'The size of the downloaded file should not exceed',
    unique: 'The fields must not match',
    notMatch: 'The values must not match',
    megabytes: 'MB',
    numeric: 'Only numbers',
    denyPastDates: 'The date must be at least the current one',
    dtStartEnd: 'The start date must be at least the end date',
    denyFutureDates: 'The date should not be more than the current one',
  },
  filterLabels: {
    name: 'Name',
    status: 'Status',
    period: 'Period',
    all: 'All',
    date: 'Date',
  },
};
