import store from '@/store';
import {allowedUnAuthNames, routeNames} from '@/router/names';

import _ from 'lodash';
import {isEmptyObj, filterObject, hasObjProp} from '@/helpers/utils';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth/getters';
import {gettersTypes as userGettersTypes} from '@/store/modules/user/getters';
import {gettersTypes as teamGettersTypes} from '@/store/modules/team/getters';
import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';
import {gettersTypes as settingsGettersTypes} from '@/store/modules/settings/getters';

const companyNames = Object.values(routeNames.main.company);
const teamNames = Object.values(routeNames.main.team);
const meetingsNames = Object.values(routeNames.main.meetings);

export function filterAccessRights(routeMeta) {
  const accessRights = _.cloneDeep(store.getters[userGettersTypes.accessRights]);

  return hasObjProp(routeMeta, 'accessRights')
    ? filterObject(accessRights, (val, key) => routeMeta.accessRights.includes(key))
    : {};
}

export function checkAccessToPage(rights) {
  if (isEmptyObj(rights)) return true;
  return Object.values(rights).some((r) => !!r);
}

export function checkCanManage(rights, moduleName) {
  const isAdmin = store.getters[userGettersTypes.isAdmin];
  const isOrganizer = store.getters[companyGettersTypes.isOrganizer];

  if (isAdmin || isOrganizer) return true;
  if (isEmptyObj(rights)) return false;
  return moduleName
    ? rights[moduleName] === 'admin' || rights[moduleName] === 'readwrite'
    : Object.values(rights).some((r) => r === 'admin' || r === 'readwrite');
}

export function checkEnabledModules() {
  return async function (to, from, next) {
    const {name, meta, params, path} = to;

    if (allowedUnAuthNames.includes(name) || path.startsWith('/authentication')) {
      next();
    } else {
      // если вкладка компании или команды - companyId этой компании,
      // иначе companyId пользователя
      const companyId = companyNames.includes(name)
        ? params.id
        : teamNames.includes(name)
        ? store.getters[teamGettersTypes.companyId]
        : store.getters[authGettersTypes.companyId];

      // default route если модуль выключен
      const defaultRouteName = companyNames.includes(name)
        ? routeNames.main.company.companyEmployees
        : teamNames.includes(name)
        ? routeNames.main.team.teamMembers
        : routeNames.main.profile;

      // если есть companyId - настройки этой компании,
      // если нет - общие настройки instance
      const gettersTypes = companyId ? companyGettersTypes : settingsGettersTypes;

      const modules = {
        adaptation_enabled: store.getters[gettersTypes.isAdaptationEnabled],
        evaluation_enabled: store.getters[gettersTypes.isEvaluationEnabled],
        feedback_enabled: store.getters[gettersTypes.isFeedbackEnabled],
        tests_and_surveys_enabled: store.getters[gettersTypes.isTestsAndSurveysEnabled],
        ht_tests_enabled: store.getters[gettersTypes.isPersonalTestsEnabled],
        interconnect_enabled: store.getters[gettersTypes.isInterconnectEnabled],
        targets_enabled: store.getters[gettersTypes.isTargetsEnabled],
        meetings_enabled: store.getters[gettersTypes.isMeetingsEnabled],
      };

      if (meta.modules) {
        const routeModules = filterObject(modules, (val, key) => meta.modules.includes(key));
        // если включен модуль
        if (Object.values(routeModules).some((enabled) => !!enabled)) next();
        else next({name: defaultRouteName, params});
        //
      } else next();
    }
  };
}

export function companyPagesGuard() {
  return async function (to, from, next) {
    const {name, params, meta, path} = to;

    if (allowedUnAuthNames.includes(name) || path.startsWith('/authentication')) {
      next();
    } else {
      const defaultRoutes = [
        routeNames.main.company.companyEmployees,
        routeNames.main.company.companyProfile,
        routeNames.main.company.companyFeedbacksUserDetails,
      ];

      const canViewCompany =
        store.getters[userGettersTypes.isAdmin] ||
        store.getters[companyGettersTypes.isCreator] ||
        store.getters[companyGettersTypes.isEmployee];

      const currentAccessRights = filterAccessRights(meta);

      // проверить уровень прав
      const checkCanManagePage = (moduleName) => {
        if (meta.accessRights.indexOf(moduleName) > -1) {
          // открывать страницу только если admin или readwrite
          if (checkCanManage(currentAccessRights, moduleName)) next();
          else next({name: defaultRoutes[0], params});
        } else next();
      };

      if (companyNames.includes(name)) {
        if (!canViewCompany) next({name: routeNames.main.profile});
        else {
          if (!defaultRoutes.includes(name)) {
            // проверка прав
            if (checkAccessToPage(currentAccessRights)) {
              checkCanManagePage('feedback_rights');
              checkCanManagePage('tests_and_surveys_rights');
            } else next({name: defaultRoutes[0], params});
          } // дефолтные вкладки
          else next();
        }
      } else next();
    }
  };
}

export function teamPagesGuard() {
  return async function (to, from, next) {
    const {name, params, meta, path} = to;

    if (allowedUnAuthNames.includes(name) || path.startsWith('/authentication')) {
      next();
    } else {
      const defaultRoutes = [routeNames.main.team.teamMembers];

      if (teamNames.includes(name)) {
        if (!defaultRoutes.includes(name)) {
          // проверка прав
          if (checkCanManage(filterAccessRights(meta))) next();
          else next({name: defaultRoutes[0], params});
        } // дефолтные вкладки
        else next();
      } else next();
    }
  };
}

export function meetingsPagesGuard() {
  return async function (to, from, next) {
    const {name, params, meta, path} = to;

    if (allowedUnAuthNames.includes(name) || path.startsWith('/authentication')) {
      next();
    } else {
      const defaultRoutes = [
        routeNames.main.meetings.meetingsParticipant,
        routeNames.main.meetings.meetingsDetails,
      ];

      if (meetingsNames.includes(name)) {
        if (!defaultRoutes.includes(name)) {
          // проверка прав
          if (checkCanManage(filterAccessRights(meta))) next();
          else next({name: defaultRoutes[0], params});
        } // дефолтные вкладки
        else next();
      } else next();
    }
  };
}

export function administrationPagesGuard() {
  return async function (to, from, next) {
    const {name, params, path} = to;

    if (allowedUnAuthNames.includes(name) || path.startsWith('/authentication')) {
      next();
    } else {
      console.log(name);
      console.log(store.getters[userGettersTypes.isPlatformAdmin]);
      if (name === routeNames.main.administration.users) {
        if (store.getters[userGettersTypes.isPlatformAdmin]) {
          next();
        } else {
          next({name: routeNames.main.dashboard, params});
        }
      } else next();
    }
  };
}
