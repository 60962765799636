export const routeAccessRights = {
  main: {
    meetings: {
      meetingsParticipant: ['meetings_rights'],
      meetingsParticipantUser: ['meetings_rights'],
      meetingsSupervisor: ['meetings_rights'],
      meetingsCreate: ['meetings_rights'],
      meetingsDetails: ['meetings_rights'],
    },
    curator: {
      curatorAdaptation: ['adaptation_rights'],
      curatorTargets: ['targets_rights'],
    },
    company: {
      companyFeed: ['feed_rights'],
      companyStatistic: [
        'adaptation_rights',
        'evaluation_rights',
        'feedback_rights',
        'ht_tests_rights',
        'interconnect_rights',
        'targets_rights',
        'tests_and_surveys_rights',
      ],
      companyScore: ['evaluation_rights'],
      companyRoadmaps: ['evaluation_rights'],
      companyEmployees: [
        'adaptation_rights',
        'evaluation_rights',
        'feedback_rights',
        'ht_tests_rights',
        'interconnect_rights',
        'targets_rights',
        'tests_and_surveys_rights',
      ],
      companyTargets: ['targets_rights'],
      companySurveys: ['tests_and_surveys_rights'],
      companySurveysEditMain: ['tests_and_surveys_rights'],
      companySurveysEditQuestions: ['tests_and_surveys_rights'],
      companySurveysEditAccess: ['tests_and_surveys_rights'],
      companySurveysEditMailing: ['tests_and_surveys_rights'],
      companySurveysSummary: ['tests_and_surveys_rights'],
      companySurveysDetails: ['tests_and_surveys_rights'],
      companyFeedbacks: ['feedback_rights'],
      companyFeedbacksMethodologies: ['feedback_rights'],
      companyFeedbacksSummary: ['feedback_rights'],
      companyFeedbacksDetails: ['feedback_rights'],
      companyFeedbacksUserDetails: ['feedback_rights'],
      companyTests: ['tests_and_surveys_rights'],
      companyTestsEditMain: ['tests_and_surveys_rights'],
      companyTestsEditQuestions: ['tests_and_surveys_rights'],
      companyTestsEditAccess: ['tests_and_surveys_rights'],
      companyTestsEditMailing: ['tests_and_surveys_rights'],
      companyTestsEditScoring: ['tests_and_surveys_rights'],
      companyResearchmentSurveys: ['tests_and_surveys_rights'],
      companyResearchmentSurveysDetails: ['tests_and_surveys_rights'],
      companyResearchmentSurveysSummary: ['tests_and_surveys_rights'],
      companyResearchmentSurveysTemplates: ['tests_and_surveys_rights'],
      companyResearchmentSurveysEditMain: ['tests_and_surveys_rights'],
      companyResearchmentSurveysEditQuestions: ['tests_and_surveys_rights'],
      companyResearchmentTestsEditMain: ['tests_and_surveys_rights'],
      companyResearchmentTestsEditQuestions: ['tests_and_surveys_rights'],
      companyResearchmentTestsEditScoring: ['tests_and_surveys_rights'],
      companyResearchmentTest: ['tests_and_surveys_rights'],
      companyResearchmentTestTemplates: ['tests_and_surveys_rights'],
      companyAdaptation: ['adaptation_rights'],
      companyAdaptationSettings: ['adaptation_rights'],
      companyStructure: ['structure_rights'],
      companyProfile: ['company_view_rights'],
      companySettings: ['settings_rights'],
      companyDemoPersonalTests: ['settings_rights'],
    },
    team: {
      teamScore: ['evaluation_rights', 'ht_tests_rights'],
      teamRoles: ['ht_tests_rights'],
      teamMembers: [
        'adaptation_rights',
        'evaluation_rights',
        'feedback_rights',
        'ht_tests_rights',
        'interconnect_rights',
        'targets_rights',
        'tests_and_surveys_rights',
      ],
    },
  },
};
