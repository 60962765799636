import en from './en';
import ru from './ru';

const messages = {
  en: {...en},
  ru: {...ru},
};
/* Ключи в компонентах приоритетны. Если там записи нет а здесь есть -
   будет сообщение отсюда, иначе из компонента */
export default messages;
