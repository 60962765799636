import adminApi from '@/api/admin';
import {mutationTypes} from '@/store/modules/admin/mutations';

export const actionsTypes = {
  getSkillsTree: '[admin] get skills tree',
  getSkillsVersions: '[admin] get skills versions',
  fixateSkillsVersion: '[admin] fixate skills version',

  registryMove: '[admin] registry move',
  registryEdit: '[admin] registry edit',

  getMaterialsByQuestion: '[admin] get materials by question',
  createMaterialsByQuestion: '[admin] create materials by question',
  deleteMaterialsByQuestion: '[admin] delete materials by question',

  getMaterialsBySkill: '[admin] get materials by skill',
  createMaterialsBySkill: '[admin] create materials by skill',
  deleteMaterialsBySkill: '[admin] delete materials by skill',

  getMaterialsBySpec: '[admin] get materials by spec',
  createMaterialsBySpec: '[admin] create materials by spec',
  deleteMaterialsBySpec: '[admin] delete materials by spec',

  changeMaterials: '[admin] change materials',
  changePulseSpec: '[admin] change pulse spec',
  getMaterialsGrades: '[admin] get materials grades',

  getSpecializations: '[admin] get specialization list',
  createSpecialization: '[admin] create specialization',
  editSpecialization: '[admin] edit specialization',
  deleteSpecialization: '[admin] delete specialization',
  importSpecializations: '[admin] import specializations',

  getSpecializationGroups: '[admin] get specialization group list',
  createSpecializationGroup: '[admin] create specialization group',
  editSpecializationGroup: '[admin] edit specialization group',
  deleteSpecializationGroup: '[admin] delete specialization group',

  getBaseProfileTree: '[admin] get base profile tree',
  editBaseProfile: '[admin] edit base profile',

  changeIndicators: '[admin] change indicators',
  deleteIndicator: '[admin] delete indicator',

  changeScales: '[admin] change scales',
  getQuestionScale: '[admin] get question scale',
  changeQuestionScale: '[admin] change question scale',

  getQuestionTooltips: '[admin] get questions tooltips',
  createQuestionTooltips: '[admin] create questions tooltips',
};

const actions = {
  [actionsTypes.getSkillsTree](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsTreeStart);
      adminApi
        .getSkillsTree(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSkillsTreeSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsTreeFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSkillsVersions](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsVersionsStart);
      adminApi
        .getSkillsVersions(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSkillsVersionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsVersionsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.fixateSkillsVersion](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.fixateSkillsVersionStart);
      adminApi
        .fixateSkillsVersion(companyId)
        .then((response) => {
          context.commit(mutationTypes.fixateSkillsVersionSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.fixateSkillsVersionFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.registryMove](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.registryMoveStart);
      adminApi
        .registryMove(data)
        .then((response) => {
          context.commit(mutationTypes.registryMoveSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.registryMoveFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.registryEdit](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.registryEditStart);
      adminApi
        .registryEdit(data)
        .then((response) => {
          context.commit(mutationTypes.registryEditSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.registryEditFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getMaterialsByQuestion](context, {companyId, questionId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMaterialsByQuestionStart);
      adminApi
        .getMaterialsByQuestion(companyId, questionId)
        .then((response) => {
          context.commit(mutationTypes.getMaterialsByQuestionSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMaterialsByQuestionFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createMaterialsByQuestion](context, {companyId, questionId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMaterialsByQuestionStart);
      adminApi
        .createMaterialsByQuestion(companyId, questionId, data)
        .then((response) => {
          context.commit(mutationTypes.createMaterialsByQuestionSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createMaterialsByQuestionFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMaterialsByQuestion](context, {companyId, questionId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMaterialsByQuestionStart);
      adminApi
        .deleteMaterialsByQuestion(companyId, questionId, data)
        .then((response) => {
          context.commit(mutationTypes.deleteMaterialsByQuestionSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.deleteMaterialsByQuestionFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },

  [actionsTypes.getMaterialsBySkill](context, {companyId, skillId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMaterialsBySkillStart);
      adminApi
        .getMaterialsBySkill(companyId, skillId)
        .then((response) => {
          context.commit(mutationTypes.getMaterialsBySkillSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMaterialsBySkillFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createMaterialsBySkill](context, {companyId, skillId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMaterialsBySkillStart);
      adminApi
        .createMaterialsBySkill(companyId, skillId, data)
        .then((response) => {
          context.commit(mutationTypes.createMaterialsBySkillSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMaterialsBySkillFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMaterialsBySkill](context, {companyId, skillId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMaterialsBySkillStart);
      adminApi
        .deleteMaterialsBySkill(companyId, skillId, data)
        .then((response) => {
          context.commit(mutationTypes.deleteMaterialsBySkillSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMaterialsBySkillFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getMaterialsBySpec](context, {specCode, companyId}) {
    context.commit(mutationTypes.getMaterialsBySpecStart);
    return new Promise((resolve, reject) => {
      adminApi
        .getMaterialsBySpec(specCode, companyId)
        .then((response) => {
          context.commit(mutationTypes.getMaterialsBySpecSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMaterialsBySpecFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createMaterialsBySpec](context, {specCode, companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMaterialsBySpecStart);
      adminApi
        .createMaterialsBySpec(specCode, companyId, data)
        .then((response) => {
          context.commit(mutationTypes.createMaterialsBySpecSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMaterialsBySpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMaterialsBySpec](context, {specCode, companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMaterialsBySpecStart);
      adminApi
        .deleteMaterialsBySpec(specCode, companyId, data)
        .then((response) => {
          context.commit(mutationTypes.deleteMaterialsBySpecSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMaterialsBySpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeMaterials](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMaterialsStart);
      adminApi
        .changeMaterials(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.changeMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changePulseSpec](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changePulseSpecStart);
      adminApi
        .changePulseSpec(data)
        .then((response) => {
          context.commit(mutationTypes.changePulseSpecSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changePulseSpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMaterialsGrades](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMaterialsGradesStart);
      adminApi
        .getMaterialsGrades(companyId)
        .then((response) => {
          context.commit(mutationTypes.getMaterialsGradesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMaterialsGradesFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getSpecializations](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationsStart);
      adminApi
        .getSpecializations(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createSpecialization](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createSpecializationStart);
      adminApi
        .createSpecialization(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.createSpecializationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createSpecializationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.editSpecialization](context, {specId, companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.editSpecializationStart);
      adminApi
        .editSpecialization(specId, companyId, data)
        .then((response) => {
          context.commit(mutationTypes.editSpecializationSuccess, {data});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.editSpecializationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteSpecialization](context, {specId, companyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteSpecializationStart);
      adminApi
        .deleteSpecialization(specId, companyId)
        .then((response) => {
          context.commit(mutationTypes.deleteSpecializationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteSpecializationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.importSpecializations](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.importSpecializationsStart);
      adminApi
        .importSpecializations(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.importSpecializationsSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.importSpecializationsFailed, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getSpecializationGroups](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationGroupsStart);
      adminApi
        .getSpecializationGroups(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSpecializationGroupsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationGroupsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createSpecializationGroup](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createSpecializationGroupStart);
      adminApi
        .createSpecializationGroups(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.createSpecializationGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createSpecializationGroupFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.editSpecializationGroup](context, {companyId, groupId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.editSpecializationGroupStart);
      adminApi
        .editSpecializationGroups(companyId, groupId, data)
        .then((response) => {
          context.commit(mutationTypes.editSpecializationGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.editSpecializationGroupFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.deleteSpecializationGroup](context, {companyId, groupId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteSpecializationGroupStart);
      adminApi
        .deleteSpecializationGroup(companyId, groupId)
        .then((response) => {
          context.commit(mutationTypes.deleteSpecializationGroupSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.deleteSpecializationGroupFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },

  [actionsTypes.getBaseProfileTree](context, {companyId, specCode}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBaseProfileTreeStart);
      adminApi
        .getBaseProfileTree(companyId, specCode)
        .then((response) => {
          context.commit(mutationTypes.getBaseProfileTreeSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBaseProfileTreeFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.editBaseProfile](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.editBaseProfileStart);
      adminApi
        .editBaseProfile(data)
        .then((response) => {
          context.commit(mutationTypes.editBaseProfileSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.editBaseProfileFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeIndicators](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeIndicatorsStart);
      adminApi
        .addBaseProfile(data)
        .then((response) => {
          context.commit(mutationTypes.changeIndicatorsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeIndicatorsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteIndicator](context, data) {
    return new Promise((resolve, reject) => {
      adminApi
        .deleteBaseProfile(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((result) => {
          reject(result);
        });
    });
  },

  [actionsTypes.changeScales](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeScalesStart);
      adminApi
        .changeScales(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.changeScalesSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeScalesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getQuestionScale](context, {companyId, questionId, specCode}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getQuestionScaleStart);
      adminApi
        .getQuestionScale(companyId, questionId, specCode)
        .then((response) => {
          context.commit(mutationTypes.getQuestionScaleSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getQuestionScaleFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeQuestionScale](context, {companyId, questionId, specCode, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeQuestionScaleStart);
      adminApi
        .changeQuestionScale(companyId, questionId, specCode, data)
        .then((response) => {
          context.commit(mutationTypes.changeQuestionScaleSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeQuestionScaleFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getQuestionTooltips](context, {companyId, questionId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getQuestionTooltipsStart);
      adminApi
        .getQuestionTooltips(companyId, questionId)
        .then((response) => {
          context.commit(mutationTypes.getQuestionTooltipsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getQuestionTooltipsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createQuestionTooltips](context, {companyId, questionId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createQuestionTooltipsStart);
      adminApi
        .createQuestionTooltips(companyId, questionId, data)
        .then((response) => {
          context.commit(mutationTypes.createQuestionTooltipsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createQuestionTooltipsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
