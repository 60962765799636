import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import SEO from '@/helpers/seo';
import i18n from '@/locale/i18n';

import {routes as authRoutes} from '@bbd/auth-vue';
import {allowedUnAuthNames, routeNames} from '@/router/names';
import {routeModules} from '@/router/modules';
import {routeAccessRights} from '@/router/accessRights';
import {
  administrationPagesGuard,
  checkEnabledModules,
  companyPagesGuard,
  meetingsPagesGuard,
  teamPagesGuard,
} from '@/router/guards';

import {actionsTypes as settingsActionsTypes} from '@/store/modules/settings/actions';
import {actionsTypes as authActionsTypes} from '@/store/modules/auth/actions';
import {actionsTypes as companyActionsTypes} from '@/store/modules/company/actions';
import {actionsTypes as teamActionsTypes} from '@/store/modules/team/actions';
import {actionsTypes as userActionsTypes} from '@/store/modules/user/actions';

import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';
import {gettersTypes as userGettersTypes} from '@/store/modules/user/getters';

import is from 'is_js';
import variables from '@/helpers/variables';
import {getFullName} from '@/helpers/utils';

import HomeView from '@/views/HomeView';
import MainView from '@/views/MainView';
// eslint-disable-next-line no-unused-vars
import AppAuthView from '@/views/AuthViewContainer';

const Dashboard = () => import('@/components/pages/dashboard/DashboardContainer');

const Profile = () => import('@/components/pages/profile/ProfileContainer');

const Resume = () => import('@/components/pages/resume/ResumeContainer');

const Testing = () => import('@/components/pages/testing/TestingContainer');

const CompanyFeed = () => import('@/components/pages/company-feed/CompanyFeedContainer');

// targets
const Targets = () => import('@/components/pages/targets/TargetsContainer');
const TargetsSelf = () => import('@/components/pages/targets/self/TargetsSelfContainer');

// meetings
const MeetingsView = () => import('@/components/pages/meetings/MeetingsViewContainer');
const Meetings = () => import('@/components/pages/meetings/MeetingsContainer');
const MeetingsParticipant = () =>
  import('@/components/pages/meetings/participant/MeetingsParticipantContainer');
const MeetingsSupervisor = () =>
  import('@/components/pages/meetings/supervisor/MeetingsSupervisorContainer');
const MeetingsCreate = () => import('@/components/pages/meetings/create/MeetingsCreateContainer');
const MeetingsDetails = () =>
  import('@/components/pages/meetings/details/MeetingsDetailsContainer');

// progress
const Progress = () => import('@/components/pages/progress/ProgressContainer');

const ProgressTests360 = () =>
  import('@/components/pages/progress/tests360/ProgressTests360Container');
const ProgressSelfTests360 = () =>
  import('@/components/pages/progress/tests360/self/ProgressSelfTests360Container');
const ProgressNotSelfTests360 = () =>
  import('@/components/pages/progress/tests360/not-self/ProgressNotSelfTests360Container');
const ProgressApprovalTests360 = () =>
  import('@/components/pages/progress/tests360/approval/ProgressApprovalTests360Container');

const ProgressFeedbacks = () =>
  import('@/components/pages/progress/feedbacks/ProgressFeedbacksContainer');
const ProgressSelfTests = () =>
  import('@/components/pages/progress/self-tests/ProgressSelfTestsContainer');
const ProgressPersonalTests = () =>
  import('@/components/pages/progress/personal-tests/ProgressPersonalTestsContainer');
const ProgressSurveys = () =>
  import('@/components/pages/progress/surveys/ProgressSurveysContainer');
const ProgressTests = () => import('@/components/pages/progress/tests/ProgressTestsContainer');

const Report = () => import('@/components/pages/report/ReportContainer');

// curator
const Curator = () => import('@/components/pages/curator/CuratorContainer');
const CuratorAdaptation = () =>
  import('@/components/pages/curator/adaptation/CuratorAdaptationContainer');
const CuratorTargets = () => import('@/components/pages/curator/targets/CuratorTargetsContainer');

// roadmap
const Roadmap = () => import('@/components/pages/roadmap/self/RoadmapContainer');
const Roadmap360 = () => import('@/components/pages/roadmap/360/Roadmap360Container');

// board
const Board = () => import('@/components/pages/board/BoardContainer');
const BoardTasks = () => import('@/components/pages/board/tasks/BoardTasksContainer');
const BoardAdaptation = () =>
  import('@/components/pages/board/adaptation/BoardAdaptationContainer');

const Company = () => import('@/components/pages/company/CompanyContainer');
const CompanyScore = () => import('@/components/pages/company/score/CompanyScoreContainer');
const CompanyMeetings = () =>
  import('@/components/pages/company/meetings/CompanyMeetingsContainer');
const CompanyRoadmaps = () =>
  import('@/components/pages/company/roadmaps/CompanyRoadmapsContainer');
const CompanyProfile = () => import('@/components/pages/company/profile/CompanyProfileContainer');
const CompanyStatistic = () =>
  import('@/components/pages/company/statistic/CompanyStatisticContainer');
const CompanyEmployees = () =>
  import('@/components/pages/company/employees/CompanyEmployeesContainer');
const CompanyTargets = () => import('@/components/pages/company/targets/CompanyTargetsContainer');
const CompanyFeedSettings = () =>
  import('@/components/pages/company/feed-settings/CompanyFeedSettingsContainer');
const CompanySettings = () =>
  import('@/components/pages/company/settings/CompanySettingsContainer');

// feedbacks
const CompanyFeedbacksView = () =>
  import('@/components/pages/company/feedbacks/CompanyFeedbacksView');
const CompanyFeedbacks = () =>
  import('@/components/pages/company/feedbacks/CompanyFeedbacksContainer');
const CompanyFeedbacksMethodologies = () =>
  import(
    '@/components/pages/company/feedbacks/methodologies/CompanyFeedbacksMethodologiesContainer'
  );
const CompanyFeedbacksReport = () =>
  import('@/components/pages/company/feedbacks/report/CompanyFeedbacksReportContainer');
const CompanyFeedbacksReportSummary = () =>
  import(
    '@/components/pages/company/feedbacks/report/summary/CompanyFeedbacksReportSummaryContainer'
  );
const CompanyFeedbacksReportDetails = () =>
  import(
    '@/components/pages/company/feedbacks/report/details/CompanyFeedbacksReportDetailsContainer'
  );

// surveys
const SurveysView = () => import('@/components/pages/company/surveys/SurveysView');
const CompanySurveys = () => import('@/components/pages/company/surveys/CompanySurveysContainer');
const CompanySurveysEdit = () =>
  import('@/components/pages/company/surveys/surveys-edit/SurveysEditContainer');
const CompanySurveysEditMain = () =>
  import(
    '@/components/pages/company/surveys/surveys-edit/surveys-edit-main/SurveysEditMainContainer'
  );
const companySurveysEditQuestions = () =>
  import(
    '@/components/pages/company/surveys/surveys-edit/surveys-edit-questions/SurveysEditQuestionsContainer'
  );
const companySurveysEditAccess = () =>
  import(
    '@/components/pages/company/surveys/surveys-edit/surveys-edit-access/SurveysEditAccessContainer'
  );
const CompanySurveysEditMailing = () =>
  import(
    '@/components/pages/company/surveys/surveys-edit/surveys-edit-mailing/SurveysEditMailingContainer'
  );
const CompanySurveysEditScoring = () =>
  import(
    '@/components/pages/company/surveys/surveys-edit/surveys-edit-scoring/SurveysEditScoringContainer'
  );
const CompanySurveysResearchment = () =>
  import('@/components/pages/company/surveys/surveys-researchment/SurveysResearchmentContainer');
const CompanySurveysResearchmentReport = () =>
  import(
    '@/components/pages/company/surveys/surveys-researchment/surveys-researchment-report/SurveysResearchmentReportContainer'
  );
const CompanySurveysResearchmentDetails = () =>
  import(
    '@/components/pages/company/surveys/surveys-researchment/surveys-researchment-details/SurveysResearchmentDetailsContainer'
  );
const CompanySurveysResearchmentTemplates = () =>
  import(
    '@/components/pages/company/surveys/surveys-researchment/surveys-researchment-templates/SurveysResearchmentTemplatesContainer'
  );
const CompanySurveysReport = () =>
  import('@/components/pages/company/surveys/surveys-report/SurveysReportContainer');
const CompanySurveysReportSummary = () =>
  import(
    '@/components/pages/company/surveys/surveys-report/surveys-report-summary/SurveysReportSummaryContainer'
  );
const CompanySurveysReportDetails = () =>
  import(
    '@/components/pages/company/surveys/surveys-report/surveys-report-details/SurveysReportDetailsContainer'
  );

const CompanyTests = () => import('@/components/pages/company/tests/CompanyTestsContainer');
const CompanyTestsEdit = () =>
  import('@/components/pages/company/tests/tests-edit/TestsEditContainer');
// const CompanyTestsEditSettings = () =>
//   import('@/components/pages/company/tests/tests-edit/TestsEditContainer');
const CompanySurveysResearchmentTests = () =>
  import('@/components/pages/company/tests/CompanyResearchmentTestsContainer');
const CompanySurveysResearchmentTestsTemplates = () =>
  import('@/components/pages/company/tests/CompanyResearchmentTestsTemplatesContainer');

// adaptation
const CompanyAdaptation = () =>
  import('@/components/pages/company/adaptation/CompanyAdaptationContainer');
const CompanyAdaptationSettings = () =>
  import('@/components/pages/company/adaptation/CompanyAdaptationSettingsContainer');

const CompanyStructure = () =>
  import('@/components/pages/company/structure/CompanyStructureContainer');

// demo
import CompanyDemoView from '@/components/pages/company/demo/CompanyDemoView';
import CompanyDemoPersonalTests from '@/components/pages/company/demo/personal-tests/CompanyDemoPersonalTests';

// team
const Team = () => import('@/components/pages/team/TeamContainer');
const TeamScore = () => import('@/components/pages/team/score/TeamScoreContainer');
const TeamRoles = () => import('@/components/pages/team/score/TeamRolesContainer');
const TeamMembers = () => import('@/components/pages/team/members/TeamMembersContainer');

const Survey = () => import('@/components/pages/survey/SurveyContainer');
const SurveyFeedback = () => import('@/components/pages/survey/SurveyFeedbackContainer');
const Documents = () => import('@/components/pages/documents/DocumentsContainer');

// Admin area
const Administration = () => import('@/components/pages/administration/AdministrationContainer');
const AdministrationUsers = () =>
  import('@/components/pages/administration/users/AdministrationUsersContainer');

const Admin = () => import('@/components/pages/admin/AdminContainer');
const AdminSkills = () => import('@/components/pages/admin/skills/AdminSkillsContainer');
const AdminSpecializations = () =>
  import('@/components/pages/admin/specializations/AdminSpecializationsContainer');

import PageNotFound from '@/components/PageNotFound';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: routeNames.home,
    component: HomeView,
  },
  {
    path: '/authentication',
    name: 'auth',
    component: AppAuthView,
    children: [...authRoutes('')],
  },
  {
    path: '',
    name: 'main',
    component: MainView,
    children: [
      {
        path: 'dashboard/:id?',
        name: routeNames.main.dashboard,
        component: Dashboard,
        meta: {
          modules: routeModules.main.dashboard,
        },
      },
      {
        path: 'profileview',
        name: routeNames.main.profile,
        component: Profile,
      },
      {
        path: 'profileview/:id',
        name: routeNames.main.userProfile,
        component: Profile,
      },
      {
        path: 'resume',
        name: routeNames.main.resume,
        component: Resume,
        meta: {
          modules: routeModules.main.resume,
        },
      },
      {
        path: 'test360/:test360Id/:specCode',
        name: routeNames.main.testing360,
        component: Testing,
        meta: {
          isTesting: true,
        },
      },
      {
        path: 'pulse/:test360Id/:specCode',
        name: routeNames.main.testing360Pulse,
        component: Testing,
        meta: {
          isTesting: true,
        },
      },
      {
        path: 'testSelf/:skillsType/:specCode',
        name: routeNames.main.testingSelf,
        component: Testing,
        meta: {
          isTesting: true,
        },
      },
      {
        path: 'feed',
        name: routeNames.main.companyFeed,
        component: CompanyFeed,
        meta: {
          modules: routeModules.main.companyFeed,
        },
      },
      {
        path: 'targets',
        component: Targets,
        children: [
          {
            path: '',
            name: routeNames.main.targets.targetsSelf,
            component: TargetsSelf,
            meta: {
              modules: routeModules.main.targets.targetsSelf,
            },
          },
        ],
      },
      {
        path: 'meetings',
        component: MeetingsView,
        children: [
          {
            path: '',
            component: Meetings,
            children: [
              {
                path: '',
                name: routeNames.main.meetings.meetingsParticipant,
                component: MeetingsParticipant,
                meta: {
                  modules: routeModules.main.meetings.meetingsParticipant,
                  accessRights: routeAccessRights.main.meetings.meetingsParticipant,
                },
              },
              {
                path: 'user/:userId?',
                props: true,
                name: routeNames.main.meetings.meetingsParticipantUser,
                component: MeetingsParticipant,
                meta: {
                  modules: routeModules.main.meetings.meetingsParticipantUser,
                  accessRights: routeAccessRights.main.meetings.meetingsParticipantUser,
                },
              },
              {
                path: 'supervisor',
                name: routeNames.main.meetings.meetingsSupervisor,
                component: MeetingsSupervisor,
                meta: {
                  modules: routeModules.main.meetings.meetingsSupervisor,
                  accessRights: routeAccessRights.main.meetings.meetingsSupervisor,
                },
              },
            ],
          },
          {
            path: 'create',
            name: routeNames.main.meetings.meetingsCreate,
            component: MeetingsCreate,
            meta: {
              modules: routeModules.main.meetings.meetingsCreate,
              accessRights: routeAccessRights.main.meetings.meetingsCreate,
            },
          },
          {
            path: 'details/:meetingId',
            props: true,
            name: routeNames.main.meetings.meetingsDetails,
            component: MeetingsDetails,
            meta: {
              modules: routeModules.main.meetings.meetingsDetails,
              accessRights: routeAccessRights.main.meetings.meetingsDetails,
            },
          },
        ],
      },
      {
        path: 'progress',
        component: Progress,
        children: [
          {
            path: '',
            component: ProgressTests360,
            children: [
              {
                path: '',
                name: routeNames.main.progress.progressSelf360,
                component: ProgressSelfTests360,
                meta: {
                  modules: routeModules.main.progress.progressSelf360,
                },
              },
              {
                path: 'notself360',
                name: routeNames.main.progress.progressNotSelf360,
                component: ProgressNotSelfTests360,
                meta: {
                  modules: routeModules.main.progress.progressNotSelf360,
                },
              },
              {
                path: 'approval360',
                name: routeNames.main.progress.progressApproval360,
                component: ProgressApprovalTests360,
                meta: {
                  modules: routeModules.main.progress.progressApproval360,
                },
              },
            ],
          },
          {
            path: 'feedbacks',
            name: routeNames.main.progress.progressFeedbacks,
            component: ProgressFeedbacks,
            meta: {
              modules: routeModules.main.progress.progressFeedbacks,
            },
          },
          {
            path: 'personal',
            name: routeNames.main.progress.progressPersonal,
            component: ProgressPersonalTests,
            meta: {
              modules: routeModules.main.progress.progressPersonal,
            },
          },
          {
            path: 'self',
            name: routeNames.main.progress.progressSelf,
            component: ProgressSelfTests,
            meta: {
              modules: routeModules.main.progress.progressSelf,
            },
          },
          {
            path: 'surveys',
            name: routeNames.main.progress.progressUserSurveys,
            component: ProgressSurveys,
            meta: {
              modules: routeModules.main.progress.progressUserSurveys,
            },
          },
          {
            path: 'tests',
            name: routeNames.main.progress.progressUserTests,
            component: ProgressTests,
            meta: {
              modules: routeModules.main.progress.progressUserTests,
            },
          },
        ],
      },
      {
        path: 'curator',
        component: Curator,
        children: [
          {
            path: 'targets',
            name: routeNames.main.curator.curatorTargets,
            component: CuratorTargets,
            meta: {
              modules: routeModules.main.curator.curatorTargets,
              accessRights: routeAccessRights.main.curator.curatorTargets,
            },
          },
          {
            path: 'adaptation',
            name: routeNames.main.curator.curatorAdaptation,
            component: CuratorAdaptation,
            meta: {
              modules: routeModules.main.curator.curatorAdaptation,
              accessRights: routeAccessRights.main.curator.curatorAdaptation,
            },
          },
        ],
      },
      {
        path: 'report',
        component: Report,
        children: [
          {
            path: '',
            name: routeNames.main.report.report360,
            component: Report,
            meta: {
              reportType: '360',
              modules: routeModules.main.report.report360,
            },
          },
          {
            path: 'self',
            name: routeNames.main.report.reportSelf,
            component: Report,
            meta: {
              reportType: 'self',
              modules: routeModules.main.report.reportSelf,
            },
          },
          {
            path: 'personality',
            name: routeNames.main.report.reportPersonal,
            component: Report,
            meta: {
              reportType: 'personal',
              modules: routeModules.main.report.reportPersonal,
            },
          },
          {
            path: 'team',
            name: routeNames.main.report.reportTeam,
            component: Report,
            meta: {
              reportType: 'team',
              modules: routeModules.main.report.reportTeam,
            },
          },
        ],
      },
      {
        path: 'devplan/user/:id',
        name: routeNames.main.roadmap.roadmapSelf,
        component: Roadmap,
        meta: {
          modules: routeModules.main.roadmap.roadmapSelf,
        },
      },
      {
        path: 'devplan360',
        component: Roadmap360,
        children: [
          {
            path: 'user/:id',
            name: routeNames.main.roadmap.roadmap360,
            component: Roadmap360,
            meta: {
              modules: routeModules.main.roadmap.roadmap360,
            },
          },
          {
            path: 'by/test/360/:testing360Id',
            name: routeNames.main.roadmap.roadmap360ByTest,
            component: Roadmap360,
            meta: {
              modules: routeModules.main.roadmap.roadmap360ByTest,
            },
          },
        ],
      },
      {
        path: 'devplan',
        component: Board,
        children: [
          {
            path: 'board/:userId?',
            name: routeNames.main.board.boardTasks,
            component: BoardTasks,
            meta: {
              modules: routeModules.main.board.boardTasks,
            },
          },
          {
            path: 'adaptations/:userId?',
            name: routeNames.main.board.boardAdaptation,
            component: BoardAdaptation,
            meta: {
              modules: routeModules.main.board.boardAdaptation,
            },
          },
        ],
      },
      {
        path: 'companyprofile/:id',
        component: Company,
        children: [
          {
            path: '',
            name: routeNames.main.company.companyStatistic,
            component: CompanyStatistic,
            meta: {
              modules: routeModules.main.company.companyStatistic,
              accessRights: routeAccessRights.main.company.companyStatistic,
            },
          },
          {
            path: 'score',
            name: routeNames.main.company.companyScore,
            component: CompanyScore,
            meta: {
              modules: routeModules.main.company.companyScore,
              accessRights: routeAccessRights.main.company.companyScore,
            },
          },
          {
            path: 'devplan',
            name: routeNames.main.company.companyRoadmaps,
            component: CompanyRoadmaps,
            meta: {
              modules: routeModules.main.company.companyRoadmaps,
              accessRights: routeAccessRights.main.company.companyRoadmaps,
            },
          },
          {
            path: 'employees',
            name: routeNames.main.company.companyEmployees,
            component: CompanyEmployees,
            meta: {
              accessRights: routeAccessRights.main.company.companyEmployees,
            },
          },
          {
            path: 'targets',
            name: routeNames.main.company.companyTargets,
            component: CompanyTargets,
            meta: {
              modules: routeModules.main.company.companyTargets,
              accessRights: routeAccessRights.main.company.companyTargets,
            },
          },
          {
            path: 'surveys',
            component: SurveysView,
            children: [
              {
                path: '',
                name: routeNames.main.company.companySurveys,
                component: CompanySurveys,
                meta: {
                  modules: routeModules.main.company.companySurveys,
                  accessRights: routeAccessRights.main.company.companySurveys,
                },
              },
              {
                path: ':surveyId',
                props: true,
                component: CompanySurveysReport,
                children: [
                  {
                    path: '',
                    name: routeNames.main.company.companySurveysSummary,
                    props: true,
                    component: CompanySurveysReportSummary,
                    meta: {
                      modules: routeModules.main.company.companySurveysSummary,
                      accessRights: routeAccessRights.main.company.companySurveysSummary,
                    },
                  },
                  {
                    path: 'details',
                    name: routeNames.main.company.companySurveysDetails,
                    props: true,
                    component: CompanySurveysReportDetails,
                    meta: {
                      modules: routeModules.main.company.companySurveysDetails,
                      accessRights: routeAccessRights.main.company.companySurveysDetails,
                    },
                  },
                ],
              },
              {
                path: ':surveyId/edit',
                component: CompanySurveysEdit,
                props: true,
                children: [
                  {
                    path: 'main',
                    name: routeNames.main.company.companySurveysEditMain,
                    props: true,
                    component: CompanySurveysEditMain,
                    meta: {
                      modules: routeModules.main.company.companySurveysEditMain,
                      accessRights: routeAccessRights.main.company.companySurveysEditMain,
                    },
                  },
                  {
                    path: 'questions',
                    name: routeNames.main.company.companySurveysEditQuestions,
                    props: true,
                    component: companySurveysEditQuestions,
                    meta: {
                      modules: routeModules.main.company.companySurveysEditQuestions,
                      accessRights: routeAccessRights.main.company.companySurveysEditQuestions,
                    },
                  },
                  {
                    path: 'access',
                    name: routeNames.main.company.companySurveysEditAccess,
                    props: true,
                    component: companySurveysEditAccess,
                    meta: {
                      modules: routeModules.main.company.companySurveysEditAccess,
                      accessRights: routeAccessRights.main.company.companySurveysEditAccess,
                    },
                  },
                  {
                    path: 'mailing',
                    name: routeNames.main.company.companySurveysEditMailing,
                    props: true,
                    component: CompanySurveysEditMailing,
                    meta: {
                      modules: routeModules.main.company.companySurveysEditMailing,
                      accessRights: routeAccessRights.main.company.companySurveysEditMailing,
                    },
                  },
                ],
              },
              {
                path: 'researchment/templates',
                name: routeNames.main.company.companyResearchmentSurveysTemplates,
                props: true,
                component: CompanySurveysResearchmentTemplates,
                meta: {
                  modules: routeModules.main.company.companyResearchmentSurveysTemplates,
                  accessRights: routeAccessRights.main.company.companyResearchmentSurveysTemplates,
                },
              },
              {
                path: 'researchment/:researchmentId',
                props: true,
                component: CompanySurveysResearchmentReport,
                children: [
                  {
                    path: 'summary',
                    name: routeNames.main.company.companyResearchmentSurveysSummary,
                    props: true,
                    component: CompanySurveysResearchmentDetails,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentSurveysSummary,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentSurveysSummary,
                    },
                  },
                  {
                    path: 'details',
                    name: routeNames.main.company.companyResearchmentSurveysDetails,
                    props: true,
                    component: CompanySurveysResearchmentDetails,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentSurveysDetails,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentSurveysDetails,
                    },
                  },
                ],
              },
              {
                path: 'researchment/:researchmentId/:tab',
                name: routeNames.main.company.companyResearchmentSurveys,
                props: true,
                component: CompanySurveysResearchment,
                meta: {
                  modules: routeModules.main.company.companyResearchmentSurveys,
                  accessRights: routeAccessRights.main.company.companyResearchmentSurveys,
                },
              },
              {
                path: 'researchment/:researchmentId/:surveyId/edit',
                component: CompanySurveysEdit,
                props: true,
                children: [
                  {
                    path: 'main',
                    name: routeNames.main.company.companyResearchmentSurveysEditMain,
                    props: true,
                    component: CompanySurveysEditMain,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentSurveysEditMain,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentSurveysEditMain,
                    },
                  },
                  {
                    path: 'questions',
                    name: routeNames.main.company.companyResearchmentSurveysEditQuestions,
                    props: true,
                    component: companySurveysEditQuestions,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentSurveysEditQuestions,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentSurveysEditQuestions,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'tests',
            component: SurveysView,
            children: [
              {
                path: '',
                name: routeNames.main.company.companyTests,
                component: CompanyTests,
                meta: {
                  modules: routeModules.main.company.companyTests,
                  accessRights: routeAccessRights.main.company.companyTests,
                },
              },
              {
                path: ':surveyId/edit',
                props: true,
                component: CompanyTestsEdit,
                children: [
                  {
                    path: 'main',
                    name: routeNames.main.company.companyTestsEditMain,
                    props: true,
                    component: CompanySurveysEditMain,
                    meta: {
                      modules: routeModules.main.company.companyTestsEditMain,
                      accessRights: routeAccessRights.main.company.companyTestsEditMain,
                    },
                  },
                  {
                    path: 'questions',
                    name: routeNames.main.company.companyTestsEditQuestions,
                    props: true,
                    component: companySurveysEditQuestions,
                    meta: {
                      modules: routeModules.main.company.companyTestsEditQuestions,
                      accessRights: routeAccessRights.main.company.companyTestsEditQuestions,
                    },
                  },
                  {
                    path: 'access',
                    name: routeNames.main.company.companyTestsEditAccess,
                    props: true,
                    component: companySurveysEditAccess,
                    meta: {
                      modules: routeModules.main.company.companyTestsEditAccess,
                      accessRights: routeAccessRights.main.company.companyTestsEditAccess,
                    },
                  },
                  {
                    path: 'mailing',
                    name: routeNames.main.company.companyTestsEditMailing,
                    props: true,
                    component: CompanySurveysEditMailing,
                    meta: {
                      modules: routeModules.main.company.companyTestsEditMailing,
                      accessRights: routeAccessRights.main.company.companyTestsEditMailing,
                    },
                  },
                  {
                    path: 'scoring',
                    name: routeNames.main.company.companyTestsEditScoring,
                    props: true,
                    component: CompanySurveysEditScoring,
                    meta: {
                      modules: routeModules.main.company.companyTestsEditScoring,
                      accessRights: routeAccessRights.main.company.companyTestsEditScoring,
                    },
                  },
                ],
              },
              {
                path: 'researchment/templates',
                name: routeNames.main.company.companyResearchmentTestTemplates,
                props: true,
                component: CompanySurveysResearchmentTestsTemplates,
                meta: {
                  modules: routeModules.main.company.companyResearchmentTestTemplates,
                  accessRights: routeAccessRights.main.company.companyResearchmentTestTemplates,
                },
              },
              {
                path: 'researchment/:researchmentId/:tab',
                name: routeNames.main.company.companyResearchmentTest,
                props: true,
                component: CompanySurveysResearchmentTests,
                meta: {
                  modules: routeModules.main.company.companyResearchmentTest,
                  accessRights: routeAccessRights.main.company.companyResearchmentTest,
                },
              },
              {
                path: 'researchment/:researchmentId/:surveyId/edit',
                component: CompanyTestsEdit,
                props: true,
                children: [
                  {
                    path: 'main',
                    name: routeNames.main.company.companyResearchmentTestsEditMain,
                    props: true,
                    component: CompanySurveysEditMain,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentTestsEditMain,
                      accessRights: routeAccessRights.main.company.companyResearchmentTestsEditMain,
                    },
                  },
                  {
                    path: 'questions',
                    name: routeNames.main.company.companyResearchmentTestsEditQuestions,
                    props: true,
                    component: companySurveysEditQuestions,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentTestsEditQuestions,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentTestsEditQuestions,
                    },
                  },
                  {
                    path: 'scoring',
                    name: routeNames.main.company.companyResearchmentTestsEditScoring,
                    props: true,
                    component: CompanySurveysEditScoring,
                    meta: {
                      modules: routeModules.main.company.companyResearchmentTestsEditScoring,
                      accessRights:
                        routeAccessRights.main.company.companyResearchmentTestsEditScoring,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'feedbacks',
            component: CompanyFeedbacksView,
            children: [
              {
                path: '',
                name: routeNames.main.company.companyFeedbacks,
                component: CompanyFeedbacks,
                meta: {
                  modules: routeModules.main.company.companyFeedbacks,
                  accessRights: routeAccessRights.main.company.companyFeedbacks,
                },
              },
              {
                path: 'methodologies',
                name: routeNames.main.company.companyFeedbacksMethodologies,
                component: CompanyFeedbacksMethodologies,
                meta: {
                  modules: routeModules.main.company.companyFeedbacksMethodologies,
                  accessRights: routeAccessRights.main.company.companyFeedbacksMethodologies,
                },
              },
              {
                path: ':feedbackId',
                props: true,
                component: CompanyFeedbacksReport,
                children: [
                  {
                    path: '',
                    name: routeNames.main.company.companyFeedbacksSummary,
                    props: true,
                    component: CompanyFeedbacksReportSummary,
                    meta: {
                      modules: routeModules.main.company.companyFeedbacksSummary,
                      accessRights: routeAccessRights.main.company.companyFeedbacksSummary,
                    },
                  },
                  {
                    path: 'details',
                    name: routeNames.main.company.companyFeedbacksDetails,
                    props: true,
                    component: CompanyFeedbacksReportDetails,
                    meta: {
                      modules: routeModules.main.company.companyFeedbacksDetails,
                      accessRights: routeAccessRights.main.company.companyFeedbacksDetails,
                    },
                  },
                  {
                    path: 'details/user/:userId?',
                    name: routeNames.main.company.companyFeedbacksUserDetails,
                    props: true,
                    component: CompanyFeedbacksReportDetails,
                    meta: {
                      modules: routeModules.main.company.companyFeedbacksUserDetails,
                      accessRights: routeAccessRights.main.company.companyFeedbacksUserDetails,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'feed-settings',
            name: routeNames.main.company.companyFeed,
            component: CompanyFeedSettings,
            meta: {
              modules: routeModules.main.company.companyFeed,
              accessRights: routeAccessRights.main.company.companyFeed,
            },
          },
          {
            path: 'adaptation',
            name: routeNames.main.company.companyAdaptation,
            component: CompanyAdaptation,
            meta: {
              modules: routeModules.main.company.companyAdaptation,
              accessRights: routeAccessRights.main.company.companyAdaptation,
            },
          },
          {
            path: 'adaptation/settings',
            name: routeNames.main.company.companyAdaptationSettings,
            component: CompanyAdaptationSettings,
            meta: {
              modules: routeModules.main.company.companyAdaptationSettings,
              accessRights: routeAccessRights.main.company.companyAdaptationSettings,
            },
          },
          {
            path: 'structure',
            name: routeNames.main.company.companyStructure,
            component: CompanyStructure,
            meta: {
              accessRights: routeAccessRights.main.company.companyStructure,
            },
          },
          {
            path: 'profile',
            name: routeNames.main.company.companyProfile,
            component: CompanyProfile,
            meta: {
              accessRights: routeAccessRights.main.company.companyProfile,
            },
          },
          {
            path: 'settings',
            name: routeNames.main.company.companySettings,
            component: CompanySettings,
            meta: {
              accessRights: routeAccessRights.main.company.companySettings,
            },
          },
          {
            path: 'demo',
            component: CompanyDemoView,
            children: [
              {
                path: 'personal',
                name: routeNames.main.company.companyDemoPersonalTests,
                component: CompanyDemoPersonalTests,
                meta: {
                  modules: routeModules.main.company.companyDemoPersonalTests,
                  accessRights: routeAccessRights.main.company.companyDemoPersonalTests,
                },
              },
            ],
          },
          {
            path: 'meetings',
            component: CompanyMeetings,
            children: [
              {
                path: '',
                component: Meetings,
                children: [
                  {
                    path: '',
                    name: routeNames.main.company.companyMeetings,
                    component: MeetingsSupervisor,
                    meta: {
                      modules: ['meetings_enabled'],
                      accessRights: ['meetings_rights'],
                    },
                  },
                ],
              },
              {
                path: 'create',
                name: routeNames.main.company.companyMeetingsCreate,
                component: MeetingsCreate,
                meta: {
                  modules: ['meetings_enabled'],
                  accessRights: ['meetings_rights'],
                },
              },
              {
                path: 'details/:meetingId',
                props: true,
                name: routeNames.main.company.companyMeetingsDetails,
                component: MeetingsDetails,
                meta: {
                  modules: ['meetings_enabled'],
                  accessRights: ['meetings_rights'],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'teamview/:id',
        component: Team,
        children: [
          {
            path: '',
            name: routeNames.main.team.teamScore,
            component: TeamScore,
            meta: {
              modules: routeModules.main.team.teamScore,
              accessRights: routeAccessRights.main.team.teamScore,
            },
          },
          {
            path: 'roles',
            name: routeNames.main.team.teamRoles,
            component: TeamRoles,
            meta: {
              modules: routeModules.main.team.teamRoles,
              accessRights: routeAccessRights.main.team.teamRoles,
            },
          },
          {
            path: 'members',
            name: routeNames.main.team.teamMembers,
            component: TeamMembers,
            meta: {
              modules: routeModules.main.team.teamMembers,
              accessRights: routeAccessRights.main.team.teamMembers,
            },
          },
        ],
      },
      {
        path: 'survey/:surveyId',
        name: routeNames.main.survey,
        props: true,
        component: Survey,
      },
      {
        path: 'feedback/:surveyId',
        name: routeNames.main.feedback,
        props: true,
        component: SurveyFeedback,
      },
      {
        path: 'documents',
        name: routeNames.main.documents,
        component: Documents,
      },
      {
        path: 'admin',
        component: Admin,
        children: [
          {
            path: 'skills',
            name: routeNames.main.admin.skills,
            component: AdminSkills,
          },
          {
            path: 'specializations',
            name: routeNames.main.admin.specializations,
            component: AdminSpecializations,
          },
        ],
      },
      {
        path: 'admin-area',
        component: Administration,
        children: [
          {
            path: 'admin-users',
            name: routeNames.main.administration.users,
            component: AdministrationUsers,
          },
        ],
      },
      {
        path: '*',
        name: routeNames.main.notFound,
        component: PageNotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const {name, params, meta} = to;
  const nextUrl = `${window.location.origin}/#${to.path}`;
  const companyNames = Object.values(routeNames.main.company);
  const teamNames = Object.values(routeNames.main.team);
  const adminNames = Object.values(routeNames.main.admin);

  SEO.registerHit(nextUrl);

  if (is.null(store.state.settings.instance)) {
    await store.dispatch(settingsActionsTypes.getInstanceSettings).then(async () => {
      jivoSetup(store.state.settings.instance.jivoId);

      if (store.state.settings.instance.SSO_DATA) {
        Vue.prototype.$sso = {
          title: 'BusinessChainId',
          icon: `${store.state.settings.instance.SSO_DATA.sso_url}/auth/favicon.ico`,
        };
        if (store.state.settings.instance.SSO_DATA.registration_enabled) {
          Vue.prototype.$registrationDisabled = false;
        }
      }
      const privacy = Vue.prototype.$privacy;

      if (store.state.settings.instance.auth_privacy)
        privacy.link = store.state.settings.instance.auth_privacy;

      if (store.state.settings.instance.auth_platform_name)
        privacy.name = store.state.settings.instance.auth_platform_name;

      Vue.prototype.$privacy = privacy;

      Vue.prototype.$useLdap = store.state.settings.instance.use_ldap;

      if (store.state.settings.instance.auth_support_email)
        Vue.prototype.$supportMail = store.state.settings.instance.auth_support_email;

      if (store.state.settings.instance.auth_support_link)
        Vue.prototype.$supportLink = store.state.settings.instance.auth_support_link;

      await store
        .dispatch(authActionsTypes.getCurrentUser)
        .then(async () => {
          await fetchCurrentUser();
          await fetchSettings();
        })
        .catch(() => {
          if (store.state.auth.isLoggedIn === false) {
            if (allowedUnAuthNames.includes(to.name) || to.path.startsWith('/authentication')) {
              return next();
            } else {
              const newTo = {
                path: '/authentication/login',
              };
              if (to.query && to.query.chat_id) {
                newTo.query = {chat_id: to.query.chat_id};
              }
              return next(newTo);
            }
            // createHiddenFrame(
            //   store.state.settings.instance.SSO_DATA.checker_url,
            //   checkSSOSession
            // );
          }
        });
    });
  } else if (store.state.auth.isLoggedIn === false && to.path.startsWith('/authentication')) {
    next();
  } else if (store.state.auth.isLoggedIn) {
    await fetchCompany();
    next();
  }

  async function fetchSettings() {
    if (!store.state.auth.currentUser.company_id) {
      await fetchCompany();
      next();
    } else
      await store
        .dispatch(companyActionsTypes.getCurrentSettings, store.state.auth.currentUser.company_id)
        .then(async () => {
          await fetchCompany();
          next();
        })
        .catch(() => next());
  }

  async function fetchCompany() {
    if (teamNames.includes(name)) await updateTeam(Number(params.id));
    else if (companyNames.includes(name)) await updateCompany(Number(params.id));
    else if (!adminNames.includes(name))
      await updateCompany(store.state.auth.currentUser.company_id);
  }

  async function fetchCurrentUser() {
    if (store.state.auth.currentUser.company_id) {
      await store.dispatch(
        userActionsTypes.getCurrentAccessRights,
        store.state.auth.currentUser.company_id
      );
    }

    await store
      .dispatch(userActionsTypes.getCurrentUser, {
        id: store.state.auth.currentUser.user_id,
      })
      .then(() => {
        const checkJivoSendUserInfo = setInterval(() => {
          jivoSendUserInfo();
          if (variables.isJivoDateSent) clearInterval(checkJivoSendUserInfo);
        }, 1000);
      });
  }

  // async function checkSSOSession(event) {
  //   if (!Object.prototype.hasOwnProperty.call(event.data, 'command')) return false;
  //
  //   const {instance} = store.state.settings;
  //   const {command, options} = event.data;
  //   const authUrl = `${instance.SSO_DATA.auth_url}&redirectedFrom=${window.location.href}`;
  //   const confirmUrl = `${instance.SSO_DATA.confirm_url}&redirectedFrom=${window.location.href}`;
  //
  //   if (command === 'refresh') {
  //     if (Object.prototype.hasOwnProperty.call(options, 'troubles')) {
  //       if (Object.prototype.hasOwnProperty.call(options, 'email_approved'))
  //         window.location.href = confirmUrl;
  //     }
  //     options.authorized
  //       ? await store
  //           .dispatch(authActionsTypes.getCurrentUser)
  //           .then(async () => {
  //             await fetchCurrentUser();
  //             await fetchSettings();
  //           })
  //           .catch(() => (window.location.href = authUrl))
  //       : (window.location.href = authUrl);
  //   }
  // }

  async function updateCompany(companyId) {
    if (!companyId) return false;

    if (
      is.null(store.state.company.metadata) ||
      store.state.company.metadata.company_id !== companyId
    ) {
      await store.dispatch(companyActionsTypes.getCompany, companyId).then(async () => {
        const canViewCompany =
          store.getters[userGettersTypes.isAdmin] ||
          store.getters[companyGettersTypes.isCreator] ||
          store.getters[companyGettersTypes.isEmployee];

        if (store.state.auth.currentUser.company_id !== companyId && canViewCompany) {
          await store.dispatch(companyActionsTypes.getSettings, companyId);
          await store.dispatch(userActionsTypes.getAccessRights, companyId);
        }
      });
    }
  }

  async function updateTeam(teamId) {
    if (!teamId) return false;

    if (is.null(store.state.team.metadata) || store.state.team.metadata.id !== teamId) {
      await store
        .dispatch(teamActionsTypes.getTeam, teamId)
        .then(async () => {
          await store.dispatch(teamActionsTypes.getCompany, teamId);
          await updateCompany(store.state.team.metadata.company_id);
        })
        .catch((result) => {
          if (result.response.status === 404) {
            router.replace({
              name: routeNames.main.notFound,
            });
          }
        });
    }
  }

  function jivoSetup(id) {
    if (!id) return false;

    const jivoUrl = `//code.jivo.ru/widget/${id}`;
    const jivoTag = document.createElement('script');

    jivoTag.setAttribute('src', jivoUrl);

    document.head.appendChild(jivoTag);

    console.log('JivoSite initialized');
  }

  function jivoSendUserInfo() {
    if (
      !window.jivo_api ||
      !store.state.settings.instance.jivoId ||
      !store.state.user.currentUser ||
      variables.isJivoDateSent
    )
      return false;

    const {currentUser} = store.state.user;
    const authUser = store.state.auth.currentUser;

    const title = {
      ru: 'Выбранная компания в профиле',
      en: 'Selected company in the profile',
    };

    const contactInfo = {
      name: getFullName(currentUser),
      email: currentUser.email,
    };

    const customData = [
      {
        title: title[i18n.locale],
        content: `${currentUser.company_name} (id=${currentUser.company_id})`,
      },
    ];
    const userToken = `bh-${currentUser.email}-${authUser.user_id}`;

    window.jivo_api.setUserToken(userToken);
    window.jivo_api.setContactInfo(contactInfo);
    window.jivo_api.setCustomData(customData);

    variables.isJivoDateSent = true;
    console.log('Jivo integration data sent');
  }

  if (from.path.indexOf('message/') > 0) {
    variables.alert.visible = true;
    variables.alert.messages.push(
      variables.alert.event[from.path.substring(from.path.indexOf('message/') + 8)]
    );
  }

  variables.isTesting = meta.isTesting;

  // if (from.query.message) {
  //   variables.alert.visible = true;
  //   variables.alert.messages.push(variables.alert.event[from.query.message]);
  // }
});

router.beforeEach(checkEnabledModules());

router.beforeEach(companyPagesGuard());

router.beforeEach(teamPagesGuard());

router.beforeEach(meetingsPagesGuard());

router.beforeEach(administrationPagesGuard());

export default router;
