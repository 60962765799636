export const mutationTypes = {
  getMeetingsParticipantStart: '[meeting] get meetings participant start',
  getMeetingsParticipantSuccess: '[meeting] get meetings participant success',
  getMeetingsParticipantFailure: '[meeting] get meetings participant failure',

  getMeetingsSupervisorStart: '[meeting] get meetings supervisor start',
  getMeetingsSupervisorSuccess: '[meeting] get meetings supervisor success',
  getMeetingsSupervisorFailure: '[meeting] get meetings supervisor failure',

  getMeetingStart: '[meeting] get meeting start',
  getMeetingSuccess: '[meeting] get meeting success',
  getMeetingFailure: '[meeting] get meeting failure',

  getQuestionsStart: '[meeting] get questions start',
  getQuestionsClear: '[meeting] get questions clear',
  getQuestionsSuccess: '[meeting] get questions success',
  getQuestionsFailure: '[meeting] get questions failure',

  getQuestionsDirectoryStart: '[meeting] get questions directory start',
  getQuestionsDirectorySuccess: '[meeting] get questions directory success',
  getQuestionsDirectoryFailure: '[meeting] get questions directory failure',

  getCommentsStart: '[meeting] get comments start',
  getCommentsSuccess: '[meeting] get comments success',
  getCommentsFailure: '[meeting] get comments failure',

  getTasksStart: '[meeting] get tasks start',
  getTasksClear: '[meeting] get tasks clear',
  getTasksSuccess: '[meeting] get tasks success',
  getTasksFailure: '[meeting] get tasks failure',

  createMeetingStart: '[meeting] create meeting start',
  createMeetingSuccess: '[meeting] create meeting success',
  createMeetingFailure: '[meeting] create meeting failure',

  changeMeetingStart: '[meeting] change meeting start',
  changeMeetingSuccess: '[meeting] change meeting success',
  changeMeetingFailure: '[meeting] change meeting failure',

  rescheduleMeetingStart: '[meeting] reschedule meeting start',
  rescheduleMeetingSuccess: '[meeting] reschedule meeting success',
  rescheduleMeetingFailure: '[meeting] reschedule meeting failure',

  doneMeetingStart: '[meeting] done meeting start',
  doneMeetingSuccess: '[meeting] done meeting success',
  doneMeetingFailure: '[meeting] done meeting failure',

  cancelMeetingStart: '[meeting] cancel meeting start',
  cancelMeetingSuccess: '[meeting] cancel meeting success',
  cancelMeetingFailure: '[meeting] cancel meeting failure',

  addQuestionsStart: '[meeting] add questions start',
  addQuestionsSuccess: '[meeting] add questions success',
  addQuestionsFailure: '[meeting] add questions failure',

  addCommentStart: '[meeting] add comment start',
  addCommentSuccess: '[meeting] add comment success',
  addCommentFailure: '[meeting] add comment failure',

  createTaskStart: '[meeting] create task start',
  createTaskSuccess: '[meeting] create task success',
  createTaskFailure: '[meeting] create task failure',

  changeTaskStart: '[meeting] change task start',
  changeTaskSuccess: '[meeting] change task success',
  changeTaskFailure: '[meeting] change task failure',

  changeShowTaskStart: '[meeting] change show task start',
  changeShowTaskSuccess: '[meeting] change show task success',
  changeShowTaskFailure: '[meeting] change show task failure',

  moveTaskStart: '[meeting] move task start',
  moveTaskSuccess: '[meeting] move task success',
  moveTaskFailure: '[meeting] move task failure',

  deleteTaskStart: '[meeting] delete task start',
  deleteTaskSuccess: '[meeting] delete task success',
  deleteTaskFailure: '[meeting] delete task failure',
};

const mutations = {
  [mutationTypes.getMeetingsParticipantStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMeetingsParticipantSuccess](state, payload) {
    state.isLoading = false;
    state.meetingsParticipant = payload;
  },
  [mutationTypes.getMeetingsParticipantFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMeetingsSupervisorStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMeetingsSupervisorSuccess](state, payload) {
    state.isLoading = false;
    state.meetingsSupervisor = payload;
  },
  [mutationTypes.getMeetingsSupervisorFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMeetingStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMeetingSuccess](state, payload) {
    state.isLoading = false;
    state.meeting = payload;
  },
  [mutationTypes.getMeetingFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getQuestionsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getQuestionsClear](state) {
    state.questions = [];
  },
  [mutationTypes.getQuestionsSuccess](state, payload) {
    state.isLoading = false;
    state.questions = payload;
  },
  [mutationTypes.getQuestionsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getQuestionsDirectoryStart](state) {
    state.isLoading = true;
    state.questionsDirectory = null;
  },
  [mutationTypes.getQuestionsDirectorySuccess](state, payload) {
    state.isLoading = false;
    state.questionsDirectory = payload;
  },
  [mutationTypes.getQuestionsDirectoryFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCommentsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCommentsSuccess](state, payload) {
    state.isLoading = false;
    state.comments = payload;
  },
  [mutationTypes.getCommentsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTasksStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTasksClear](state) {
    state.tasks = [];
  },
  [mutationTypes.getTasksSuccess](state, payload) {
    state.isLoading = false;
    state.tasks = payload;
  },
  [mutationTypes.getTasksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createMeetingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMeetingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMeetingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMeetingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMeetingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMeetingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.rescheduleMeetingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.rescheduleMeetingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.rescheduleMeetingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.doneMeetingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.doneMeetingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.doneMeetingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.cancelMeetingStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.cancelMeetingSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.cancelMeetingFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.addQuestionsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.addQuestionsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.addQuestionsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.addCommentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.addCommentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.addCommentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeShowTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeShowTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeShowTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.moveTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.moveTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.moveTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTaskStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTaskFailure](state) {
    state.isSubmitting = false;
  },
};

export default mutations;
